// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
import { AdminProvider } from './contexts/AdminContext';
import GlobalStyle from './GlobalStyle';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#a1c4a2',
    },
    background: {
      default: '#142b16',
      paper: '#426147',
    },
    text: {
      primary: '#f2edb6',
      secondary: '#f0edc9'
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            color: '#999', // Label color
            '&.Mui-focused': {
              color: '#ffffff', // Label color when focused
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#fffff', // Darker border color
            },
            '&:hover fieldset': {
              borderColor: '#999', // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: '#fff', // Border color when focused
            },
          },
          '& .MuiInputBase-input': {
            color: '#f0edc9', // Text color
          },
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <AdminProvider>
        <BrowserRouter>
          <ThemeProvider theme={darkTheme}>
            <GlobalStyle />
            <CssBaseline />
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </AdminProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);