import { getFirestore, collection, getDoc, getDocs, query, where, doc, updateDoc, setDoc, deleteDoc, addDoc, increment, arrayUnion } from 'firebase/firestore';
import { LiveOrder, Course, Member, OpenOrder, Player, Result, Sport, Team, SportsEventOdds, PendingOrder, SettledOrder, Feedback } from './types/Types';
import { firestore } from './firebaseConfig';

const db = firestore

// Add User
export const addUser = async (uid: string, email: string, nickName: string) => {
  if (uid && email) {
    const userDocRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userDocRef)
    if (userDoc.exists()) {
      return { success: false, error: `User: ${uid} already exists.` };
    }
    try {
      await setDoc(doc(db, 'users', uid), {
        email: email,
        wallet: 100,
        nickName: nickName
      });
      // console.log('User added to Firestore successfully');
    } catch (error) {
      console.error('Error adding user to Firestore:', error);
      throw new Error('Error adding user to Firestore');
    }
  }
};

// Update User
export const updateUser = async (user: { uid: string;[key: string]: any }): Promise<Result<null>> => {
  const teamDocRef = doc(collection(db, 'users'), user.uid);
  try {
    await updateDoc(teamDocRef, user);
    return { success: true, data: null };
  } catch (error) {
    console.error('Error updating user: ', error);
    return { success: false, error: 'Error updating user: ' + error };
  }
};

// Add a New Team
export const addTeam = async (team: { [key: string]: any }): Promise<Result<null>> => {
  const q = query(collection(db, 'teams2'), where('name', '==', team.name));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    // console.log(`Team with name ${team.name} already exists.`);
    return { success: false, error: `Team with name ${team.name} already exists.` };
  }

  const teamDocRef = doc(collection(db, 'teams2'));
  try {
    await setDoc(teamDocRef, team);
    // console.log(`Team ${team.id} added successfully.`);
    return { success: true, data: null };
  } catch (error) {
    console.error('Error adding team: ', error);
    return { success: false, error: 'Error adding team: ' + (error instanceof Error ? error.message : String(error)) };
  }
};

// Update a Team
export const updateTeam = async (team: { id: string;[key: string]: any }): Promise<Result<null>> => {
  if (team.name) {
    const q = query(collection(db, 'teams2'), where('name', '==', team.name));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      // console.log(`Team with name ${team.name} already exists.`);
      return { success: false, error: `Team with name ${team.name} already exists.` };
    }
  }

  const teamDocRef = doc(collection(db, 'teams2'), team.id);
  try {
    await updateDoc(teamDocRef, team);
    // console.log(`Team ${team.id} updated successfully.`);
    return { success: true, data: null };
  } catch (error) {
    console.error('Error updating team: ', error);
    return { success: false, error: 'Error updating team: ' + error };
  }
};

// Get All Teams
export const getTeams = async (): Promise<Result<Team[]>> => {
  const teamsSnapshot = await getDocs(collection(db, 'teams2'));
  const teamsList: Team[] = await Promise.all(
    teamsSnapshot.docs.map(async (doc) => {
      const teamData = doc.data();
      const players = await Promise.all(
        teamData.players.map(async (player_id: string) => {
          const result = await getPlayer(player_id);
          return result.success ? result.data : null;
        })
      );
      const validPlayers = players.filter((player) => player !== null) as Player[];
      const maxTotalScore = Math.max(...validPlayers.map(player => player.totalSort));

      // Calculate total score excluding the lowest score
      const totalScore = validPlayers
        .reduce((acc, player) => acc + player.totalSort, 0);

      return {
        ...teamData,
        id: doc.id,
        players: validPlayers,
        total_score: totalScore - maxTotalScore,
        holes_left: players.reduce((acc, player) => acc + 18 - player.thruSort, 0)
      } as Team;
    })
  );

  return { success: true, data: teamsList };
};

// Get All Team Owners
export const getOwners = async (): Promise<Result<Member[]>> => {
  const ownersSnap = await getDocs(collection(db, 'users'));

  let maxScore = -Infinity;

  const ownersList: Member[] = ownersSnap.docs.map(doc => ({
    id: doc.id,
    profile_picture_url: 'https://xsgames.co/randomusers/avatar.php?g=male',
    ...doc.data(),
  } as Member));
  return { success: true, data: ownersList }
};

// Get User
export const getUser = async (user_id: string): Promise<Result<Member>> => {
  const userDocRef = doc(db, 'users', user_id);
  const userDoc = await getDoc(userDocRef)
  if (userDoc.exists()) {
    const userData = userDoc.data();
    return {
      success: true,
      data: userData as Member
    }
  }
  else {
    return { success: false, error: 'No such User!' };
  }
};

// Fetch the nickname of a user
export const getUserNickName = async (userId: string): Promise<string> => {
  const userDoc = await getDoc(doc(firestore, 'users', userId));
  if (userDoc.exists()) {
    return userDoc.data().nickName;
  }
  return 'Unknown User';
};

// Get Single Player
export const getPlayer = async (player_id: string): Promise<Result<Player>> => {
  const playerDocRef = doc(db, 'players', player_id);
  try {
    const playerDoc = await getDoc(playerDocRef);

    if (playerDoc.exists()) {
      const data = playerDoc.data();
      return {
        success: true,
        data: {
          id: playerDoc.id,
          firstName: data.firstName,
          lastName: data.lastName,
          displayName: data.displayName,
          country: data.country,
          position: data.position,
          score: data.score,
          scoreSort: data.scoreSort,
          rounds: data.rounds,
          total: data.total,
          totalSort: data.totalSort,
          totalStrokes: parseInt(data.totalStrokes, 10),
          oddsToWin: data.oddsToWin ?? 0,
          oddsSort: data.oddsSort,
          thru: data.thru,
          thruSort: data.thruSort === 19 ? 18 : data.thruSort,
          rank: data.rank === -1 ? 999 : data.rank,
          teeTime: data.teeTime ?? "DEAD",
          profile_picture_url: data.profile_picture_url || 'https://xsgames.co/randomusers/avatar.php?g=male'
        } as Player
      };
    } else {
      return { success: false, error: 'No such player!' };
    }
  } catch (error) {
    console.error('Error getting player:', error);
    return { success: false, error: 'Error getting player' };
  }
};


// Get All Players
export const getPlayers = async (): Promise<Result<Player[]>> => {
  const playersSnapshot = await getDocs(collection(db, 'players'));

  let maxScore = -Infinity;

  const playersList: Player[] = playersSnapshot.docs.map(doc => {
    const data = doc.data();
    const playerScore = data.scoreSort

    if (playerScore > maxScore && data.position !== "CUT" && data.position !== "WD") {
      maxScore = playerScore;
    }

    return {
      id: doc.id,
      firstName: data.firstName,
      lastName: data.lastName,
      displayName: data.displayName,
      country: data.country,
      position: data.position,
      score: data.score,
      scoreSort: data.scoreSort,
      rounds: data.rounds,
      total: data.total,
      totalStrokes: parseInt(data.totalStrokes, 10),
      oddsToWin: data.oddsToWin ?? "+99999999",
      oddsSort: data.oddsSort ?? 0,
      thru: data.thru,
      thruSort: data.thruSort === 19 ? 18 : data.thruSort,
      rank: data.rank === -1 ? 999 : data.rank,
      teeTime: data.teeTime ?? "DEAD",
      profile_picture_url: data.profile_picture_url || 'https://xsgames.co/randomusers/avatar.php?g=male'
    } as Player;
  });

  return { success: true, data: playersList }
};

export const getCourse = async (course_id: string): Promise<Result<Course>> => {
  const courseDocRef = doc(db, 'courses', course_id);
  try {
    const courseDoc = await getDoc(courseDocRef);
    if (courseDoc.exists()) {
      const data = courseDoc.data();
      return {
        success: true,
        data: {
          id: courseDoc.id,
          name: data.name,
          city: data.city,
          country: data.country,
          par: data.par,
          yardage: data.yardage
        } as Course
      };
    } else {
      return { success: false, error: 'No such course!' };
    }
  } catch (error) {
    console.error('Error getting course:', error);
    return { success: false, error: 'Error getting course' };
  }
};


// Get All Open Bets
export const getAllOpenBets = async (): Promise<Result<OpenOrder[]>> => {
  const snapShot = await getDocs(collection(db, 'open_bets'));

  const betList: OpenOrder[] = snapShot.docs.map(doc => {
    const bet = doc.data();

    return {
      id: doc.id,
      ...bet,
    } as OpenOrder;
  });

  return { success: true, data: betList }
};

// Get All Live Bets
export const getAllLiveBets = async (): Promise<Result<LiveOrder[]>> => {
  const snapShot = await getDocs(collection(db, 'live_bets'));

  const betList: LiveOrder[] = snapShot.docs.map(doc => {
    const bet = doc.data();

    return {
      id: doc.id,
      ...bet,
    } as LiveOrder;
  });

  return { success: true, data: betList }
};

// Get Open Bets for Bid/Ask Chart
export const getOpenBets = async (bet_id: string, market: string, name: string): Promise<Result<LiveOrder[]>> => {
  try {
    // const snapShot = await getDocs(collection(db, 'open_bets'));

    // const baseQuery = query(collection(db, 'open_bets'),
    //   where('event_id', '==', bet_id),
    //   where('market', '==', market)
    // );

    const baseQuery = [
      where('event_id', '==', bet_id),
      where('market', '==', market),
    ];

    if (market === 'outRights') {
      // console.log('outRights True')
      baseQuery.push(where('line.name', '==', name));
    }
    // console.log(baseQuery)

    const q = query(collection(db, 'open_bets'), ...baseQuery);

    const docSnap = await getDocs(q);

    const betList: LiveOrder[] = docSnap.docs.map((doc) => {
      const bet = doc.data() as LiveOrder;
      // console.log("BET: ", bet)
      return bet as LiveOrder;
    });

    return { success: true, data: betList }
  } catch (error) {
    return { success: false, error: 'Failed to fetch Live Bets with bet_id: ' + bet_id }
  }
};


// Get All Settled Bets
export const getSettledBets = async (): Promise<Result<SettledOrder[]>> => {
  const snapShot = await getDocs(collection(db, 'settled_bets'));

  const betList: SettledOrder[] = snapShot.docs.map(doc => {
    const bet = doc.data();

    return {
      id: doc.id,
      ...bet,
    } as SettledOrder;
  });

  return { success: true, data: betList }
};

export const offerBet = async (bet: PendingOrder): Promise<OpenOrder> => {
  try {
    const betRef = doc(collection(db, 'open_bets'));
    const betDoc = await getDoc(betRef);
    const newBet = {
      id: betDoc.id,
      ...bet
    }
    await setDoc(betRef, newBet);
    return newBet;
  } catch (error) {
    console.error('Error adding bet to Firestore:', error);
    throw new Error('Error adding bet to Firestore');
  }
};

export const takeBet = async (bet_id: string, user_id: string): Promise<void> => {
  const betDocRef = doc(db, 'open_bets', bet_id);

  try {
    const betDoc = await getDoc(betDocRef);
    if (betDoc.exists()) {
      const bet = betDoc.data() as LiveOrder;
      bet.taker_id = user_id;
      bet.status = 'live'; // Optionally update the status

      // Create a new document in the live_bets collection
      const liveBetRef = doc(collection(db, 'live_bets'));
      await setDoc(liveBetRef, {
        ...bet,
        id: liveBetRef.id // Ensure the new bet in live_bets has a unique ID
      });

      await deleteDoc(betDocRef);
    } else {
      console.error('Error finding bet', bet_id);
      throw new Error('Error finding bet: ' + bet_id);
    }
  } catch (error) {
    console.error('Error taking bet:', error);
    throw new Error('Error taking bet');
  }
};

export const fetchOdds = async (sport_key: string): Promise<Result<SportsEventOdds[]>> => {

  // Check Cache
  const cacheKey = `odds_${sport_key}`;
  const cachedData = localStorage.getItem(cacheKey);
  const cacheTime = localStorage.getItem(`${cacheKey}_time`);
  const now = Date.now();
  if (cachedData && cacheTime && now - parseInt(cacheTime, 10) < 5 * 60 * 1000) {
    console.log("Retrieving Cached Odds")
    return { success: true, data: JSON.parse(cachedData) }
  }

  const collectionRef = collection(db, `odds/sports/${sport_key}`);
  const snapshot = await getDocs(collectionRef);
  const docs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as SportsEventOdds));
  // setDocuments(docs);
  console.log("Updating Cache: ", docs)
  localStorage.setItem(cacheKey, JSON.stringify(docs));
  localStorage.setItem(`${cacheKey}_time`, now.toString());

  return { success: true, data: docs }
};

export const fetchSports = async (): Promise<Result<Sport[]>> => {
  // This is a mock function. Replace it with a real API call to fetch Vegas odds.
  const playersSnapshot = await getDocs(collection(db, 'sports'));

  const betList: Sport[] = playersSnapshot.docs.map(doc => {
    const bet = doc.data();
    return {
      id: doc.id,
      ...bet,
    } as Sport;
  });

  return { success: true, data: betList }
};

export const fetchSport = async (sport_key: string): Promise<Result<Sport>> => {
  // This is a mock function. Replace it with a real API call to fetch Vegas odds.
  const courseDocRef = doc(db, 'sports', sport_key);

  try {
    const courseDoc = await getDoc(courseDocRef);
    if (courseDoc.exists()) {
      const data = courseDoc.data();
      return {
        success: true,
        data: {
          id: courseDoc.id,
          key: data.key,
          group: data.group,
          title: data.title,
          description: data.description,
          active: data.active,
          has_outrights: data.has_outrights,
        } as Sport
      };
    } else {
      return { success: false, error: 'No such Sport!' };
    }
  } catch (error) {
    console.error('Error getting sport:', error);
    return { success: false, error: 'Error getting sport' };
  }
};

// export const submitFeedback = async (feedback: string, user_id: string): Promise<void> => {
//   try {
//     await addDoc(collection(db, 'feedback'), {
//       feedback,
//       user_id: user_id,
//       timestamp: new Date()
//     });
//   } catch (error) {
//     console.error('Error adding feedback to Firestore:', error);
//     throw new Error('Error adding feedback to Firestore');
//   }
// };

// export const fetchOdds = async (): Promise<any[]> => {
//   return [
//     { id: '1', description: 'Hole In One', odds: '100' },
//     { id: '2', description: 'Scottie to win', odds: '150' },
//     { id: '3', description: 'Joel D to become a woman', odds: '1000' },
//     { id: '4', description: 'Slug to die first', odds: '555' },
//     { id: '5', description: 'Simmmons to Wed First', odds: '250' },
//     { id: '6', description: 'Plourde to Beat Jub', odds: '250' },
//   ];
// };

// export const fetchWeddingFutures = async (): Promise<any[]> => {
//   return [
//     { id: '0', name: 'Jub', price: '-150', market: 'outRights' },
//     { id: '1', name: 'Goose', price: '100', market: 'outRights' },
//     { id: '2', name: 'Simmons', price: '1000', market: 'outRights' },
//     { id: '3', name: 'Finn', price: '1000', market: 'outRights' },
//     { id: '4', name: 'Slug', price: '10000', market: 'outRights' },
//     { id: '5', name: 'Bloq', price: '10000', market: 'outRights' },
//     { id: '6', name: 'Chuck', price: '10000', market: 'outRights' },
//     { id: '7', name: 'Noah', price: '10000', market: 'outRights' },
//     { id: '8', name: 'Crow', price: '10000', market: 'outRights' },
//   ];
// };

export const fetchWeddingFutures = async (): Promise<Result<any[]>> => {
  // This is a mock function. Replace it with a real API call to fetch Vegas odds.
  const snapshot = await getDocs(collection(db, 'odds/specials/weddingFutures'));

  const oddsList = snapshot.docs.map(doc => {
    const line = doc.data();
    return {
      id: doc.id,
      ...line,
    };
  });

  return { success: true, data: oddsList }
};

export const fetchSpecial = async (specialId: string): Promise<Result<any[]>> => {
  // This is a mock function. Replace it with a real API call to fetch Vegas odds.
  const snapshot = await getDocs(collection(db, 'odds/specials/', specialId));

  const oddsList = snapshot.docs.map(doc => {
    const line = doc.data();
    return {
      id: doc.id,
      ...line,
    };
  });

  return { success: true, data: oddsList }
};

export const fetchSpecialsKeyWords = async (): Promise<Result<any[]>> => {
  // This is a mock function. Replace it with a real API call to fetch Vegas odds.
  const snapshot = await getDocs(collection(db, 'odds/specials/keywords'));

  const oddsList = snapshot.docs.map(doc => {
    const line = doc.data();
    return {
      id: doc.id,
      ...line,
    };
  });

  return { success: true, data: oddsList }
};

// export const fetchSpecials = async (keyword: string, market: string): Promise<Result<any[]>> => {
//   let collectionPath = 'odds/specials/events';
//   let oddsCollectionPath = '';

//   if (market === "outRights") {
//     collectionPath = 'odds/specials/outRights';
//     oddsCollectionPath = 'odds';
//   } else {
//     oddsCollectionPath = 'bookmakers';
//   }

//   console.log(collectionPath, " ", market, " ", keyword);

//   const q = query(collection(db, collectionPath), where('keyword', '==', keyword));
//   const querySnapshot = await getDocs(q);

//   const oddsList = await Promise.all(querySnapshot.docs.map(async (doc) => {
//     const line = doc.data();

//     let odds = [];
//     if (market === "outRights") {
//       const oddsRef = collection(db, `${collectionPath}/${doc.id}/${oddsCollectionPath}`);
//       const oddsSnapshot = await getDocs(oddsRef);
//       odds = oddsSnapshot.docs.map(oddsDoc => oddsDoc.data());
//     } else {
//       const bookmakersRef = collection(db, `${collectionPath}/${doc.id}/${oddsCollectionPath}`);
//       const bookmakersSnapshot = await getDocs(bookmakersRef);
//       odds = await Promise.all(bookmakersSnapshot.docs.map(async (bookmakerDoc) => {
//         const bookmakerData = bookmakerDoc.data();
//         const oddsRef = collection(db, `${collectionPath}/${doc.id}/${oddsCollectionPath}/${bookmakerDoc.id}/odds`);
//         const oddsSnapshot = await getDocs(oddsRef);
//         const oddsData = oddsSnapshot.docs.map(oddsDoc => oddsDoc.data());
//         return { bookmaker: bookmakerData, odds: oddsData };
//       }));
//     }

//     return {
//       id: doc.id,
//       ...line,
//       odds
//     };
//   }));

//   return { success: true, data: oddsList };
// };

interface Line {
  name: string;
  price: number;
  point?: number;
}

interface Market {
  key: string,
  outcomes: Line[];
  // spreads: { outcomes: Line[] };
  // totals: { outcomes: Line[] };
}

interface Bookmaker {
  id: string;
  name: string;
  markets: Market[];
}

interface Special {
  id: string;
  keyword: string;
  description: string;
  bookmakers: Bookmaker[];
}

// interface Result<T> {
//   success: boolean;
//   data: T;
// }

export const fetchSpecials = async (keyword: string, market: string): Promise<Result<Special[]>> => {
  let collectionPath = 'odds/specials/events'; // Default path for non-outRights markets
  let oddsCollectionPath = 'bookmakers';

  if (market === "outRights") {
    collectionPath = 'odds/specials/outRights';
  }

  // console.log(collectionPath, " ", market, " ", keyword);

  const q = query(collection(firestore, collectionPath), where('keyword', '==', keyword));
  const querySnapshot = await getDocs(q);

  const specialsList = await Promise.all(querySnapshot.docs.map(async (doc) => {
    const event = doc.data();

    let bookmakers: Bookmaker[] = [];
    if (market === "outRights") {
      const oddsRef = collection(firestore, `${collectionPath}/${doc.id}/${oddsCollectionPath}`);
      const oddsSnapshot = await getDocs(oddsRef);
      bookmakers = await Promise.all(oddsSnapshot.docs.map(async oddsDoc => {
        const oddsData = oddsDoc.data();
        const marketsRef = collection(firestore, `${collectionPath}/${doc.id}/${oddsCollectionPath}/${oddsDoc.id}/markets`);
        const marketsSnapshot = await getDocs(marketsRef);
        const markets = marketsSnapshot.docs.map(marketDoc => {
          const marketData = marketDoc.data();
          return {
            key: marketData.key,
            outcomes: marketData.outcomes ? marketData.outcomes.map((outcome: any) => ({
              name: outcome.name,
              price: outcome.price,
              point: outcome.point
            })) : []
          };
        });

        return {
          id: oddsDoc.id,
          name: oddsData.name,
          title: oddsData.title,
          last_updated: oddsData.last_updated,
          markets: markets
        };
      }));
    } else {
      const bookmakersRef = collection(firestore, `${collectionPath}/${doc.id}/${oddsCollectionPath}`);
      const bookmakersSnapshot = await getDocs(bookmakersRef);
      bookmakers = await Promise.all(bookmakersSnapshot.docs.map(async (bookmakerDoc) => {
        const bookmakerData = bookmakerDoc.data();
        const oddsRef = collection(firestore, `${collectionPath}/${doc.id}/${oddsCollectionPath}/${bookmakerDoc.id}/markets`);
        const oddsSnapshot = await getDocs(oddsRef);
        const markets = Object.entries(
          oddsSnapshot.docs.reduce((acc: any, oddsDoc) => {
            const oddsData = oddsDoc.data();
            const marketType = oddsData.key;
            if (!acc[marketType]) {
              acc[marketType] = { outcomes: [], key: marketType };
            }
            oddsData.outcomes.map((outcome: any) => {
              acc[marketType].outcomes.push({
                name: outcome.name,
                price: outcome.price,
                point: outcome.point,
              });
            });
            return acc;
          }, {} as { [key: string]: { outcomes: any[]; key: string } })
        ).map(([key, market]) => market as Market);

        // console.log(markets);
        return {
          id: bookmakerDoc.id,
          name: bookmakerData.name,
          title: bookmakerData.title,
          markets: markets,
          last_updated: bookmakerData.last_updated
        };
      }));
    }

    return {
      id: doc.id,
      keyword: event.keyword,
      bookmakers: bookmakers,
      away_team: event.away_team,
      home_team: event.home_team,
      description: event.description,
      commence_time: event.commence_time,
      sport_title: event.title,
      location: event.location
    };
  }));

  return { success: true, data: specialsList };
};

// Fetch all feedbacks
export const getFeedbacks = async (): Promise<Feedback[]> => {
  const feedbacksCollection = collection(firestore, 'feedbacks');
  const feedbacksSnapshot = await getDocs(feedbacksCollection);
  const feedbackList = feedbacksSnapshot.docs.map(doc => {
    const data = doc.data();
    return {
      id: doc.id,
      content: data.content,
      userId: data.userId,
      timestamp: (data.timestamp as unknown as { toDate: () => Date }).toDate(),
      upvotes: data.upvotes,
      downvotes: data.downvotes,
      responses: data.responses.map((response: any) => ({
        content: response.content,
        userId: response.userId,
        timestamp: (response.timestamp as unknown as { toDate: () => Date }).toDate()
      }))
    } as Feedback;
  });
  return feedbackList;
};

// Submit new feedback
export const submitFeedback = async (content: string, userId: string): Promise<string> => {
  const feedbacksCollection = collection(firestore, 'feedbacks');
  const feedbackDoc = await addDoc(feedbacksCollection, {
    content,
    userId,
    timestamp: new Date(),
    upvotes: 0,
    downvotes: 0,
    responses: []
  });
  return feedbackDoc.id;
};

// Submit a response to a feedback
export const submitResponse = async (feedbackId: string, content: string, userId: string): Promise<void> => {
  const feedbackDoc = doc(firestore, 'feedbacks', feedbackId);
  await updateDoc(feedbackDoc, {
    responses: arrayUnion({ content, userId, timestamp: new Date() })
  });
};

// Upvote a feedback
export const upvoteFeedback = async (feedbackId: string): Promise<void> => {
  const feedbackDoc = doc(firestore, 'feedbacks', feedbackId);
  await updateDoc(feedbackDoc, {
    upvotes: increment(1)
  });
};

// Downvote a feedback
export const downvoteFeedback = async (feedbackId: string): Promise<void> => {
  const feedbackDoc = doc(firestore, 'feedbacks', feedbackId);
  await updateDoc(feedbackDoc, {
    downvotes: increment(1)
  });
};



// const firestore = getFirestore();

// interface Market {
//   type: string;
//   outcomes: any[];
// }

// export const correctMarketsField = async (bookmakerDocId: string, oddsDocId: string): Promise<void> => {
//   if (!bookmakerDocId || !oddsDocId) {
//     throw new Error('bookmakerDocId and oddsDocId are required');
//   }

//   const bookmakerDocRef = doc(firestore, `bookmakers/${bookmakerDocId}/odds/${oddsDocId}`);

//   try {
//     const bookmakerDoc = await getDoc(bookmakerDocRef);

//     if (!bookmakerDoc.exists()) {
//       throw new Error('Bookmaker document not found');
//     }

//     const markets = bookmakerDoc.data()?.markets;
//     if (typeof markets !== 'object' || markets === null) {
//       throw new Error('Markets field is not an object');
//     }

//     const marketsArray: Market[] = Object.keys(markets).map((key) => ({
//       type: key,
//       outcomes: markets[key].outcomes,
//     }));

//     await updateDoc(bookmakerDocRef, { markets: marketsArray });

//     console.log('Markets field corrected to array format');
//   } catch (error) {
//     console.error('Failed to correct markets field', error);
//     throw new Error('Failed to correct markets field');
//   }
// };
