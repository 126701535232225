// src/pages/PlayerSelection.tsx
import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, FormControl, InputLabel, Select, MenuItem, List, ListItem, ListItemText, Button, RadioGroup, FormControlLabel, Radio, TextField, Alert } from '@mui/material';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Player } from '../types/Types';
import { addTeam, getPlayers, updateUser } from '../firestoreServices';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import PayPalButton from '../components/PayPalButton';

const PlayerSelection: React.FC = () => {
  const navigate = useNavigate();
  const { currentAuthUser } = useAuth();

  const [players, setPlayers] = useState<Player[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<{ [key: number]: string }>({}); // State for selected players
  const [sortedGroups, setSortedGroups] = useState<Player[][]>([]);
  const [teamName, setTeamName] = useState<string>('');
  const [nickName, setNickName] = useState<string>('');
  const [showPayment, setShowPayment] = useState<boolean>(false);

  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');

  // TODO: Make a general League Config Context?
  const [sortingMethod, setSortingMethod] = useState<string>('odds');

  useEffect(() => {
    const fetchPlayers = async () => {
      const result = await getPlayers();
      if (!result.success) {
        setError(result.error)
        return;
      }
      setPlayers(result.data);
    };
    fetchPlayers();
  }, []);

  useEffect(() => {
    sortAndGroupPlayers();
  }, [players, sortingMethod]);

  const sortAndGroupPlayers = () => {
    const sorted = [...players].sort((a, b) => {
      if (sortingMethod === 'odds') return b.oddsSort - a.oddsSort;
      return a.rank - b.rank;
      return 0;
    });

    const groups: Player[][] = [];
    for (let i = 0; i < 5; i++) {
      const group = sorted.slice(i * 10, (i + 1) * 10);
      groups.push(group);
    }
    groups.push(sorted.slice(50));
    setSortedGroups(groups);
    // console.log(groups)
  };

  const allGroupsSelected = () => {
    for (let i = 0; i <= 5; i++) {
      if (!selectedPlayers[i]) {
        return false;
      }
    }
    return true;
  };

  const handleSubmitForm = async () => {
    if (teamName === "") {
      setError('Please Enter Team Name');
      return;
    }

    if (!allGroupsSelected()) {
      setError('Please Select a Player from all 6 Groups');
      return;
    }

    setMessage('Hop on a mobile if you want to use Venmo')
    setError('');
    setShowPayment(true);
  };

  const handleSelectionChange = (groupIndex: number, playerId: string) => {
    setSelectedPlayers(prevState => ({
      ...prevState,
      [groupIndex]: playerId,
    }));
  };

  const handlePayPalSuccess = async () => {
    if (teamName === "") {
      setError('Please Enter Team Name');
      return;
    }

    if (nickName === "") {
      setError('Please Enter Nick Name');
      return;
    }

    if (!allGroupsSelected()) {
      setError('Please Select a Player from all 6 Groups');
      return;
    }

    // currentAuthUser.nickName = nickName
    currentAuthUser.hasTeam = true
    const updateUserResult = await updateUser({ uid: currentAuthUser.uid, hasTeam: true, nickName: nickName })
    if (!updateUserResult.success) {
      setError('Error Setting Owners Nickname');
    }

    const team = { name: teamName, owner_id: currentAuthUser.uid, players: Object.values(selectedPlayers) }
    const result = await addTeam(team)
    if (!result.success) {
      setMessage('');
      setError(result.error ?? 'An unknown error occurred.');
    }
    else {
      setMessage('Team Submitted! Navigating to The ThunderDome!');
      setError('');
      currentAuthUser.hasTeam = true
      navigate('/dashboard')
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 4
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mb: 4 }}>
          Select Your Players
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mb: 3,
          }}
        >
          {showPayment ? (
            <Box sx={{ mt: 0 }}>
              {/* <Typography component="h2" variant="h6">Complete Payment with Venmo</Typography> */}
              <PayPalButton handlePayPalSuccess={handlePayPalSuccess} />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="teamName"
                  label="Team Name"
                  name="teamName"
                  autoFocus
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  sx={{ mb: 3, mr: 4 }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="nickName"
                  label="Owners Name"
                  name="nickName"
                  autoFocus
                  value={nickName}
                  onChange={(e) => setNickName(e.target.value)}
                  sx={{ mb: 3 }}
                />
              </Box>
              <Button
                type="button"
                variant="contained"
                size='large'
                sx={{ ml: 2 }}
                onClick={handlePayPalSuccess} // TODO: This should be handleSubmitForm
              >
                Submit Team
              </Button>
            </Box>
          )
          }
        </Box>
        {message && <Alert sx={{ mb: 2 }} severity="success">{message}</Alert>}
        {error && <Alert sx={{ mb: 2 }} severity="error">{error}</Alert>}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 2,
            mb: 2,
          }}
        >
          {sortedGroups.slice(0, 3).map((group, index) => (
            <Box key={index} sx={{ backgroundColor: 'background.paper', padding: 2, borderRadius: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h6">Group {index + 1}</Typography>
              </Box>
              <RadioGroup
                name={`group-${index}`}
                value={selectedPlayers[index] || ''}
                onChange={(e) => handleSelectionChange(index, e.target.value)}
              >
                <List>
                  {group.map(player => (
                    <ListItem key={player.id} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                      <FormControlLabel
                        value={player.id}
                        control={<Radio />}
                        label={player.displayName + " " + player.oddsToWin}
                      />
                    </ListItem>
                  ))}
                </List>
              </RadioGroup>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 2,
            mb: 2,
          }}
        >
          {sortedGroups.slice(3, 6).map((group, index) => (
            <Box key={index + 3} sx={{ backgroundColor: 'background.paper', padding: 2, borderRadius: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h6">Group {index + 4}</Typography>
              </Box>
              <RadioGroup
                name={`group-${index}`}
                value={selectedPlayers[index + 3] || ''}
                onChange={(e) => handleSelectionChange(index + 3, e.target.value)}
              >
                <List>
                  {group.map(player => (
                    <ListItem key={player.id} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                      <FormControlLabel
                        value={player.id}
                        control={<Radio />}
                        label={player.displayName + " " + player.oddsToWin}
                      />
                    </ListItem>
                  ))}
                </List>
              </RadioGroup>
            </Box>
          ))}
        </Box>
        {/* <Box
          sx={{
            backgroundColor: 'background.paper',
            padding: 2,
            borderRadius: 2,
            mt: 2,
          }}
        >
          <Typography variant="h6">Group 6</Typography>
          <RadioGroup
            name="group-5"
            value={selectedPlayers[5] || ''}
            onChange={(e) => handleSelectionChange(5, e.target.value)}
          >
            <List>
              {sortedGroups[5]?.map(player => (
                <ListItem key={player.id}>
                  <FormControlLabel
                    value={player.id}
                    control={<Radio />}
                    label={`${player.name} (Rank: ${player.rank}, Odds: ${player.odds})`}
                  />
                </ListItem>
              ))}
            </List>
          </RadioGroup>
        </Box> */}
        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 3 }}
          onClick={handlePayPalSuccess}
        >
          Submit Team
        </Button>
      </Box>
    </Container>
  );
};

export default PlayerSelection;
