



// Temp Function to convert backing to lay odds.

import { Line, OpenOrder, Spread, Total } from "../../types/Types";

// Need to update if we introduce vig
export const calculateLayOdds = (backingOdds: number): number => {
  // let layOdds: number;

  // if (backingOdds > 0) {
  //   // For positive American odds
  //   const impliedProbability = 100 / (backingOdds + 100);
  //   layOdds = -100 / impliedProbability;
  // } else {
  //   // For negative American odds
  //   const impliedProbability = -backingOdds / (-backingOdds + 100);
  //   layOdds = 100 / impliedProbability - 100;
  // }

  // return Math.round(layOdds);
  return -1 * backingOdds;
};

export const market2Text = (market: string): string => {
  switch (market) {
    case 'h2h':
      return "Money Line"
      break;
    case 'spreads':
      return "Spread"
      break;
    case 'totals':
      return "Total"
    default:
      return ""
      break;
  }
}

function isSpread(line: Line): line is Spread {
  return (line as Spread).point !== undefined;
}

function isTotal(line: Line): line is Total {
  return (line as Total).point !== undefined;
}

export const getPrimaryText = (bet: OpenOrder): string => {
  switch (bet.market) {
    case 'h2h':
      return `${bet.description} Money Line`;
    case 'spreads':
      if (isSpread(bet.line)) {
        return `${bet.description} @ ${bet.line.point > 0 ? "+" + bet.line.point : bet.line.point}`;
      }
      return `${bet.description} Spread`;
    case 'totals':
      if (isTotal(bet.line)) {
        return `${bet.description} Total @ ${bet.line.point}`;
      }
      return `${bet.description} Total`;
    case 'outRights':
      return `${bet.description} ${bet.sport_title}`;
    default:
      return "";
  }
};

export const getSecondaryText = (bet: OpenOrder): string => {
  switch (bet.market) {
    case 'h2h':
      return "Money Line"
      break;
    case 'spreads':
      return "Spread"
      break;
    case 'totals':
      return "Total"
    default:
      return ""
      break;
  }
}

export const calculateOpenContractPrice = (bet: OpenOrder): number => {
  // console.log(bet)
  if (bet.line.price > 0 || bet.line.price === -100) {
    // Positive American odds
    return (Math.abs(bet.line.price) / 100) * bet.amount;
} else {
    // Negative American odds
    const total = (Math.abs(bet.line.price) / 100) * bet.amount
    const diff = total - bet.amount
    return Math.abs(bet.amount - diff)
}
}