// BidAskChart.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Chart, registerables, Tick, TooltipItem } from 'chart.js';
import { Order, OrderBook } from '../../types/exchangeTypes';
import { collection, getFirestore, onSnapshot } from 'firebase/firestore';
import { firestore } from '../../firebaseConfig';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTheme } from '@mui/material/styles';
import { Line, OpenOrder, OrderSide, SportsEvent, SportsEventOdds } from '../../types/Types';
import { Box } from '@mui/material';

interface BidAskChartProps {
  openBets: OpenOrder[],
  // onClick: (bet_id: Line, betType: string | undefined, betSide: OrderSide | undefined, evt: SportsEventOdds | null) => void,
}

Chart.register(...registerables, ChartDataLabels);

const BidAskChart: React.FC<BidAskChartProps> = ({ openBets }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const theme = useTheme();

  const [lays, setLays] = useState<any[]>([]);
  const [backs, setBacks] = useState<any[]>([]);
  const [combinedData, setCombinedData] = useState<any[]>([]);
  const [orderDetails, setOrderDetails] = useState<{ [key: number]: OpenOrder[] }>({});

  useEffect(() => {
    const calculateOrderBookData = (openBets: OpenOrder[]) => {
      const orderDetailsTemp: { [key: number]: OpenOrder[] } = {};
      const aggregateOrders = (orders: OpenOrder[], side: 'back' | 'lay') => {
        const aggregated = orders.reduce((acc, order) => {
          const existing = acc.find((o) => o.y === order.line.price && o.side === side);
          if (existing) {
            existing.x += order.amount;
          } else {
            acc.push({ x: order.amount, y: order.line.price, side });
          }
          if (!orderDetailsTemp[order.line.price]) {
            orderDetailsTemp[order.line.price] = [];
          }
          orderDetailsTemp[order.line.price].push(order);
          return acc;
        }, [] as { x: number; y: number; side: string }[]);
        return aggregated.sort((a, b) => a.y - b.y);
      };

      setOrderDetails(orderDetailsTemp);

      const layOrders = aggregateOrders(openBets.filter(order => order.orderSide === 'lay'), 'lay');
      const backOrders = aggregateOrders(openBets.filter(order => order.orderSide === 'back'), 'back');

      setBacks(backOrders)
      setLays(layOrders)

      const combined = [...layOrders, ...backOrders].sort((a, b) => a.y - b.y);
      setCombinedData(combined);

      // const combined: React.SetStateAction<any[]> = [];
      // const priceSet = new Set(backOrders.map(order => order.y).concat(layOrders.map(order => order.y)));
      // priceSet.forEach(price => {
      //   combined.push(...layOrders.filter(order => order.y === price));
      //   combined.push(...backOrders.filter(order => order.y === price));
      // });
    
      setCombinedData(combined);

      // Debugging specific price points
      // console.log('Lay Orders:', layOrders);
      // console.log('Back Orders:', backOrders);
      // console.log('Combined Data:', combined);
      // console.log(Array.from(new Set(combinedData.map(order => order.y))))
      // console.log('Order Details:', orderDetailsTemp);
    };

    // if (openBets.length > 0) {
    calculateOrderBookData(openBets);
    // }
  }, [openBets]);

  let delayed: boolean;

  const maxVolume = Math.max(
    ...lays.map(bid => Math.abs(bid.x)),
    ...backs.map(ask => Math.abs(ask.x))
  );

  const buffer = maxVolume * 0.2;

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        const bidAskChart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: Array.from(new Set(combinedData.map(order => order.y))),
            datasets: [
              // {
              //   label: 'Orders',
              //   data: combinedData,
              //   backgroundColor: (context: any) => {
              //     const order = combinedData[context.dataIndex];
              //     if (order) {
              //       return order.side === 'back' ? 'rgba(0, 255, 0, 0.3)' : 'rgba(255, 0, 0, 0.3)';
              //     }
              //   },
              //   borderColor: (context: any) => {
              //     const order = combinedData[context.dataIndex];
              //     if (order) {
              //       return order.side === 'back' ? 'rgba(0, 255, 0, 0.7)' : 'rgba(255, 0, 0, 0.7)';
              //     }
              //   },
              //   categoryPercentage: 0.90,
              //   barPercentage: 0.90,
              //   // borderWidth: 1
              // },
              {
                label: 'Backs',
                // data: backs.map((back) => ({ x: back.x, y: back.y })),
                data: combinedData.filter(order => order.side === 'back'),
                backgroundColor: 'rgba(0, 255, 0, 0.3)',
                borderColor: 'rgba(0, 255, 0, 0.7)',
                // borderWidth: 1,
                // barThickness: 30,
                categoryPercentage: .99,
                barPercentage: .99,
                maxBarThickness: 30,
                // barThickness: 20,
                // yAxisID: 'y-axis-backs',
              },
              {
                label: 'Lays',
                data: combinedData.filter(order => order.side === 'lay').map(order => ({ x: -order.x, y: order.y, side: order.side })),
                // data: combinedData.filter(order => order.side === 'lay').map(order => ({ ...order, x: -order.x })),
                backgroundColor: 'rgba(255, 0, 0, 0.3)',
                borderColor: 'rgba(255, 0, 0, 0.7)',
                // borderWidth: 1,
                // barThickness: 30,
                categoryPercentage: .99,
                barPercentage: .99,
                maxBarThickness: 30,
                // barThickness: 20,
                // yAxisID: 'y-axis-lays',
              }
            ]
          },
          options: {
            indexAxis: 'y',
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Volume',
                  color: theme.palette.text.secondary
                },
                min: -Math.max(...combinedData.map(order => Math.abs(order.x))) - buffer,
                max: Math.max(...combinedData.map(order => Math.abs(order.x))) + buffer,
                stacked: true,
                // reverse: (value: { side: string; }) => (!!(value.side === 'side')),
                ticks: {
                  color: theme.palette.text.secondary,
                  // display: true,
                  callback: function (value: any) {
                    return Math.abs(value);
                  },
                },
              },
              y: {
                title: {
                  display: true,
                  text: 'Odds',
                  color: theme.palette.text.secondary
                },
                stacked: true,
                reverse: true,
                ticks: {
                  // stepSize: 10,
                  // display: true,
                  color: theme.palette.text.secondary,
                  callback: (tickValue: string | number, index: number, ticks: Tick[]) => {
                    const labels = Array.from(new Set(combinedData.map(order => order.y)));
                    const tick = labels[index];
                    return tick ? (tick > 0 ? "+" + tick : tick) : '';
                  },
                  // major: {
                  //   enabled: true,
                  // },
                },
              },
            },
            plugins: {
              legend: {
                labels: {
                  color: theme.palette.text.secondary, // Color of the text for the legend keys
                }
              },
              tooltip: {
                callbacks: {
                  title: function (context: any) {
                    return '';
                  },
                  label: function (context: any) {
                    return `${Math.abs(context.raw.x)}$ of ${context.dataset.label} at ${context.raw.y > 0 ? "+" + context.raw.y : context.raw.y} `;
                  },
                  afterLabel: function (context: any) {
                    const price = context.raw.y;
                    const ordersAtPrice = orderDetails[price] || [];
                    const orderDetailsText = ordersAtPrice.filter(order => order.orderSide === context.raw.side).map(order => `${order.amount}$`).join('\n');
                    return `Individual Order Sizes:\n${orderDetailsText}`;
                  }
                }
              },
              datalabels: {
                display: true,
                color: theme.palette.text.secondary,
                formatter: function (value: any) {
                  return Math.abs(value.x) + " $";
                },
                // anchor: 'start',
                // align: 'end'
                anchor: function (context) {
                  return context.dataset.label === 'Backs' ? 'end' : 'start';
                },
                align: function (context) {
                  return context.dataset.label === 'Backs' ? 'end' : 'start';
                },
              },
            },
            onClick: (event: any, elements: any, chart: any) => {
              if (elements.length > 0) {
                const element = elements[0];
                const datasetIndex = element.datasetIndex;
                const index = element.index;
                const price = chart.data.datasets[datasetIndex].data[index].y;
                const side = chart.data.datasets[datasetIndex].data[index].side;
                const ordersAtPrice = orderDetails[price] || [];
                // onClick('wedding?' , 'h2h', side, null);
                // alert(`\nOrders at ${price}:\n${ordersAtPrice.map(order => `Amount: ${order.amount}, Type: ${order.orderSide}`).join('\n')}`);
              }
            },
            animation: {
              onComplete: () => {
                delayed = true;
              },
              delay: (context) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                  delay = context.dataIndex * 10 + context.datasetIndex * 100;
                }
                return delay;
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            
          },
        });

        return () => {
          bidAskChart.destroy();
        };
      }
    }
  }, [combinedData, orderDetails, theme]);

  return (
    <Box sx={{ position: 'relative', height: '400px', width: '100%' }}>
      <canvas ref={chartRef} style={{ width: '100%', height: '100%' }}></canvas>
    </Box>
  )
};

export default BidAskChart;
