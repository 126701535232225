// src/components/BettingExchange.tsx
import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, TextField, Button, List, ListItem, ListItemText, Divider, Paper, Avatar, Grid, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, ToggleButtonGroup, ToggleButton, Collapse } from '@mui/material';
import { fetchOdds, fetchSports, fetchSpecial, fetchSpecials, fetchWeddingFutures, getAllLiveBets, getOpenBets, getAllOpenBets, getSettledBets, offerBet, takeBet, updateUser, fetchSpecialsKeyWords } from '../firestoreServices';
import { LiveOrder, Line, Market, Member, MoneyLine, OpenOrder, Sport, SportsEventOdds, Spread, Total, OrderType, OrderSide, PendingOrder, SettledOrder, SportsEvent } from '../types/Types';
import { useAuth } from '../contexts/AuthContext';

interface BettingExchangeProps {
  // owners: Member[];
}

const BoysTrip: React.FC<BettingExchangeProps> = ({ }) => {
  const { currentAuthUser, profile } = useAuth();

  // Generalized Betting Catagories
  const [selectedSpecial, setSelectedSpecial] = useState<any>({ title: "" });


  // All Sports From API
  const [sports, setSports] = useState<Sport[]>([])

  // Place Order Form
  const [betDescription, setBetDescription] = useState('');
  const [betAmount, setBetAmount] = useState('');
  const [odds, setOdds] = useState('');
  const [line, setLine] = useState<Line | null>(null);
  const [event, setEvent] = useState<SportsEventOdds | null>(null);
  const [market, setMarket] = useState<string>('');
  const [orderSide, setOrderSide] = useState<OrderSide>('back');
  const [orderType, setOrderType] = useState<OrderType>('limit');

  // Book?
  const [openBets, setOpenBets] = useState<OpenOrder[]>([]);
  const [liveBets, setLiveBets] = useState<LiveOrder[]>([]);
  const [settledBets, setSettledBets] = useState<SettledOrder[]>([]);

  // Odds Types
  const [recommendedBets, setRecommendedBets] = useState<any[]>([]);
  const [specials, setSpecials] = useState<any[]>([]);
  const [specialBets, setSpecialBets] = useState<any[]>([]);
  const [weddingFutures, setWeddingFutures] = useState<any[]>([]);
  const [highVolumeBets, setHighVolumeBets] = useState<any[]>([]);
  const [outRightBets, setOutRightBets] = useState<any[]>([]);
  const [mlSpreadTots, setMlSpreadTots] = useState<SportsEventOdds[]>([]);

  // Rendered Odds
  const [filteredBets, setFilteredBets] = useState<any[]>([]);
  const [renderedBets, setRenderedBets] = useState<any[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<SportsEventOdds[]>([]);

  // Page State
  const [loading, setLoading] = useState(true);
  const [selectedSport, setSelectedSport] = useState<Sport | null>(null);
  const [selectedCatagory, setSelectedCatagory] = useState<string>("");

  const [openOrder, setOpenOrder] = useState<string | null>(null);

  const handleToggle = (orderId: string) => {
    setOpenOrder(openOrder === orderId ? null : orderId);
  };

  // useEffect(() => {
  //   const getWeddingFutures = async () => {
  //     const weddingFutures = await fetchWeddingFutures();
  //     setWeddingFutures(weddingFutures)
  //   };
  //   getWeddingFutures();
  // }), [selectedPoison === 'WeddingFutures'];

  useEffect(() => {
    setFilteredBets([]);
    setLoading(true);
    const fetchBets = async () => {
      const result1 = await getAllLiveBets();
      if (result1.success) {
        setLiveBets(result1.data);
      }

      const result2 = await getAllOpenBets();
      if (result2.success) {
        setOpenBets(result2.data)
      }

      const result3 = await getSettledBets();
      if (result3.success) {
        setSettledBets(result3.data)
      }
    };

    const fetchAndSetOdds = async () => {
      const sportsResult = await fetchSports();
      if (sportsResult.success) {
        setSports(sportsResult.data)
      }

      if (selectedSport) {
        const response = await fetchOdds(selectedSport.key);
        if (response.success) {
          if (selectedSport.has_outrights) {
            setOutRightBets(response.data)
          }
          else {
            setMlSpreadTots(response.data)
          }
        }
      }

      const specialsQuery = await fetchSpecialsKeyWords();
      if (specialsQuery.success)
        setSpecials(specialsQuery.data)

      // const response = await fetchWeddingFutures();
      // if (response.success)
      //   setWeddingFutures(response.data)

      setLoading(false); // Set loading to false after fetching is complete
    };

    fetchBets();
    fetchAndSetOdds();

    setFilteredBets([])

  }, [selectedSport])

  const handleAddToSlip = async () => {
    if (!betDescription || !odds || !betAmount) {
      alert('Please fill in all fields.');
      return;
    }
    const parsedOdds = parseInt(odds, 10);
    const parsedAmount = parseInt(betAmount, 10);

    if (isNaN(parsedOdds) || isNaN(parsedAmount)) {
      alert('Please enter valid numbers for odds and amount.');
      return;
    }

    // const partialBet = {
    //   sport_title: selectedSport?.title,
    //   description: betDescription,
    //   line: line,
    //   market: market,
    //   amount: parsedAmount,
    //   seeker_id: currentAuthUser.uid,
    //   status: 'open',
    // } as PendingOrder;

    // try {
    //   const newBet = await offerBet(partialBet);
    //   setOpenBets([...openBets, newBet]);
    //   setOdds('');
    //   setBetDescription('');
    //   setBetAmount('');
    // } catch (error) {
    //   console.error('Error creating bet:', error);
    //   alert('Failed to create bet. Please try again.');
    // }
  };

  const filterOpenBets = (bet_id: string, market: string, name: string) => {
    // console.log(openBets)
    return openBets.filter((bet) => {
      return bet.event_id === bet_id && bet.market === market && (market !== "outRights" || bet.line.name === name);
    });
  };

  const handlePlaceOrder = async () => {
    if (!betDescription || !odds || !betAmount) {
      alert('Please fill in all fields.');
      return;
    }
    const parsedOdds = parseInt(odds, 10);
    const parsedAmount = parseInt(betAmount, 10);

    if (isNaN(parsedOdds) || isNaN(parsedAmount)) {
      alert('Please enter valid numbers for odds and amount.');
      return;
    }

    if (line === null) {
      alert('Error: Line is Null: Please report this issue and try selecting a different line from the recommended wagers');
      return;
    }

    if (!profile) {
      alert('Ghost Profile Detected');
      return;
    }
    if (profile.wallet - parsedAmount < 0) {
      alert('You are too poor to make this bet.');
      return;
    }

    const partialBet = {
      sport_title: selectedSport?.title ?? 'To Marry First',
      event_id: event ? event.id : 'wedding?',
      description: betDescription,
      market: market,
      line: { ...line, price: parsedOdds },
      amount: parsedAmount,
      seeker_id: currentAuthUser.uid,
      status: 'open',
      orderSide: orderSide,
      orderType: orderType,
    } as PendingOrder;

    try {
      const newBet = await offerBet(partialBet);
      updateUser({ uid: currentAuthUser.uid, wallet: profile.wallet - partialBet.amount })
      profile.wallet -= partialBet.amount;
      setOpenBets([...openBets, newBet]);
      setOdds('');
      setBetDescription('');
      setBetAmount('');
    } catch (error) {
      console.error('Error creating bet:', error);
      alert('Failed to create bet. Please try again.');
    }
  };

  const handleSportChange = async (key: any) => {
    // if (key === "weddingFutures") {
    //   setSelectedPoison("weddingFutures")
    //   setSelectedBetType("outRights")
    //   setSelectedSport(null)
    //   setFilteredBets(betTypes['weddingFutures'])
    //   return
    // }
    const sport = sports.find(sport => sport.key === key);
    if (sport) {
      // setSelectedPoison('')
      setSelectedSpecial({ title: '' })
      setSelectedSport(sport);
    }
  };

  const handleCatagoryChange = (key: string) => {
    setSelectedCatagory(key);
    setSelectedSport(null)
  }

  const handleListItemClick = (bet: Line, market: string = '', side: OrderSide = 'back', event: SportsEventOdds | null) => {
    setBetDescription(bet.name);
    setLine(bet);
    setEvent(event);
    setMarket(market);
    // setEvent(event);
    // setBetAmount(bet.amount.toString());
    setOrderSide(side)
    setOdds(bet.price.toString());
  };

  const renderHeader = () => {
    return (
      <>
        {
          true ? (
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'background.default',
              borderRadius: '8px',
              padding: '16px',
              border: '1px solid',
              borderColor: 'divider'
            }}>
              <Box flex='1'>
                <Typography variant="h6">Player</Typography>
              </Box>
              <Grid container spacing={2} justifyContent="space-between" flex="1">
                <Grid item xs={6} display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="h6" noWrap>Buy</Typography>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="h6" noWrap sx={{ minWidth: 100 }}>Sell</Typography>
                </Grid>
              </Grid>
            </Box >
          ) : (
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'background.default',
              borderRadius: '8px',
              padding: '16px',
              border: '1px solid',
              borderColor: 'divider'
            }}>
              <Box flex='1'>
                <Typography>Participants</Typography>
              </Box>
              <Box flex='1' display={{ xs: 'none', md: 'block' }}>
                <Typography variant="body1">Details</Typography>
              </Box>
              <Grid container spacing={2} justifyContent="space-between" flex="1">
                <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                  <Typography noWrap sx={{ minWidth: 100 }}>Money Line</Typography>
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                  <Typography>Spread</Typography>
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                  <Typography>Total</Typography>
                </Grid>
              </Grid>
            </Box >
          )
        }
      </>
    )
  };

  const renderEventHeader = () => {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'background.default',
        borderRadius: '8px',
        padding: '16px',
        border: '1px solid',
        borderColor: 'divider'
      }}>
        <Grid container spacing={2} justifyContent="space-between" flex="1">
          <Grid item xs={4} display="flex">
            <Typography variant="h6">Course</Typography>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h6" noWrap>Info</Typography>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h6" noWrap sx={{ minWidth: 100 }}>Tee Time</Typography>
          </Grid>
        </Grid>
      </Box >
    )
  };

  const events = [
    { course: 'Red Fox Course', info: 'Round 1', commence_time: '11/08/2024 12:34:00' },
    { course: 'Southern Pines Golf Club', info: 'Round 2', commence_time: '11/09/2024 12:23:00' },
    { course: 'Mid Pines Golf Course', info: 'Round 3', commence_time: '11/10/2024 12:45:00' },
    { course: 'Tobacco Road Golf Course', info: 'Round 4', commence_time: '11/11/2024 11:12:00' },
  ]

  const renderEvents = () => {
    return events.map((event: any, index: number) => (
      <Box key={index}>
        <ListItem key={index}
          onClick={() => { }}//TODO: This needs an id
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'background.default',
            borderRadius: '8px',
            marginBottom: '8px',
            padding: '16px',
            // transition: 'transform 0.2s',
            // '&:hover': {
            //   transform: 'scale(1.02)'
            // },
            display: 'row', border: '1px solid', borderColor: 'divider'
          }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} display="flex">
              <ListItemText primary={event.course} />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
              <Typography noWrap>{event.info}</Typography>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body1">{new Date(event.commence_time).toLocaleString()}</Typography>
            </Grid>
          </Grid>
        </ListItem>
        {/* <Collapse in={openOrder === bet.name}>
          <Box sx={{ pl: 2, pb: 3 }}>
            <BidAskChart openBets={filterOpenBets(filteredBets[0].id, 'outRights', bet.name)} />
          </Box>
        </Collapse> */}
      </Box>

    ))
    // });
  };

  const renderEventModule = () => {
    // console.log("FilteredBets: ", filteredBets)
    return (
      <>
        {renderEventHeader()}
        <List sx={{ maxHeight: 600, overflowY: 'auto', scrollbarWidth: 'none' }}>
          {renderEvents()}
        </List>
      </>
    );
  };

  interface Player {
    seed: number;
    name: string;
  }

  interface BracketProps {
    players: Player[];
  }

  const generateRounds = (players: Player[]) => {
    let rounds = [];
    let currentRound = players;

    // Preliminary round
    rounds.push(currentRound);
    rounds.push(placeHolders);

    // Main rounds
    while (currentRound.length > 1) {
      const nextRound = [];
      for (let i = 0; i < currentRound.length; i += 2) {
        if (currentRound[i + 1]) {
          nextRound.push({ seed: Math.min(currentRound[i].seed, currentRound[i + 1].seed), name: '' });
        } else {
          nextRound.push(currentRound[i]);
        }
      }
      rounds.push(nextRound);
      currentRound = nextRound;
    }
    // rounds.push(currentRound); // Add the final round (champion)
    return rounds;
  };

  const generateRounds2 = (players: Player[]) => {
    let rounds = [];
    let currentRound = players;

    // Preliminary round
    // rounds.push(team1);
    // rounds.push(team2);

    // Main rounds - Each player on team1 plays each player on team2, generating 4 rounds
    for (let round = 0; round < 4; round++) {
      const mainRound = [];
      for (let i = 0; i < 4; i++) {
        mainRound.push({ player1: team1[i], player2: team2[(i + round) % 4] });
      }
      rounds.push(mainRound);
    }
    // rounds.push(currentRound); // Add the final round (champion)
    return rounds;
  };

  const team1: Player[] = [
    { seed: 0, name: 'Bloq' },
    { seed: 0, name: 'Barry' },
    { seed: 0, name: 'Meier' },
    { seed: 0, name: 'Jub' }
  ];

  const team2: Player[] = [
    { seed: 0, name: 'Elia' },
    { seed: 0, name: 'Plourde' },
    { seed: 0, name: 'Simmons' },
    { seed: 0, name: 'McGov' },
  ];

  const players: Player[] = [
    { seed: 0, name: 'Bloq' },
    { seed: 0, name: 'Plourde' },
    { seed: 0, name: 'Meier' },
    { seed: 0, name: 'Jub' },
    { seed: 0, name: 'Elia' },
    { seed: 0, name: 'Barry' },
    { seed: 0, name: 'Simmons' },
    { seed: 0, name: 'McGov' },
  ];

  const placeHolders: Player[] = [
    { seed: 1, name: '1 Seed' },
    { seed: 8, name: '8 Seed' },
    { seed: 3, name: '3 Seed' },
    { seed: 6, name: '6 Seed' },
    { seed: 4, name: '4 Seed' },
    { seed: 5, name: '5 Seed' },
    { seed: 2, name: '2 Seed' },
    { seed: 7, name: '7 Seed' },
  ];

  const rounds = generateRounds(players);
  const rounds2 = generateRounds2(players);

  const calculateBracketYDistance = (roundIndex: number) => {
    if (roundIndex == 0)
      return 1
    else
      return (roundIndex - 1) * 4 + 1
  }

  const getRoundHeader = (roundIndex: number) => {
    if (roundIndex === 5)
      return ""
    else
      return `Round ${roundIndex}`
  }

  const getRoundHeader2 = (roundIndex: number) => {
    if (roundIndex === 0)
      return "Preliminary"
    if (roundIndex === 4)
      return ""
    else
      return `Round ${roundIndex}`
  }

  const renderBracket = () => {
    return (
      <Box>
        <Typography variant="body1" align="center">
          The first option is a Ryder Cup style tournament.
        </Typography>
        <Typography variant="body1" align="center">
          Two teams will be constructed prior to the start of the first round.
        </Typography>
        <Typography variant="body1" align="center">
          Each round will consist of 4 matchups.
        </Typography>
        <Typography variant="body1" align="center">
          Each matchup will consist of 1 player from each team.
        </Typography>
        <Typography variant="body1" align="center">
          Each matchup will participate in a head to head match play round, scoring points for their team.
        </Typography>
        <Typography variant="body1" align="center">...</Typography>
        <Typography variant="body1" align="center">
          A player scores 1/2 point for tying a hole and 1 point for winning a hole.
        </Typography>
        <Typography variant="body1" align="center">
          The team with the most points at the end of the final round wins.
        </Typography>
        <Typography variant="body1" align="center">...</Typography>
        <Typography variant="body1" align="center">
          In this format the Green Jacket will be awarded to the MVP of the winning team.
        </Typography>
        <Typography variant="body1" align="center">
          The MVP is the player that scored the most points for their team.
        </Typography>
        <Typography variant="body1" align="center">...</Typography>
        <Typography variant="body1" align="center">
          If there is a tie, the tie breaker will go to the player with the higher total match play score accumulated from all 4 rounds.
        </Typography>
        <Typography variant="body1" align="center">
          It is important to note that an individual matchup is scored the moment there is a determined winner and not at the end, if played out.
        </Typography>
        <Typography variant="body1" align="center">
          Example of Tie Breaker:
        </Typography>
        <Typography variant="body1" align="center">
          Player 1: 3 and 2, 4 and 2, 7 and 5
        </Typography>
        <Typography variant="body1" align="center">
          Player 1: 4 and 3, 4 and 1, 
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" my={5}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography width={180} variant="h6" align="center">Team 1</Typography>
            {team1.map((player, playerIndex) => (
              <Box key={playerIndex} display="flex" flexDirection="column" alignItems="center" mx={2}>
                <Box key={playerIndex} display="flex" flexDirection="column" alignItems="center" my={1}>
                  <Box
                    width={150}
                    height={50}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid black"
                    borderRadius={4}
                    bgcolor='#7d1818'
                  >
                    <Typography variant="body1">
                      {player.name}
                    </Typography>
                  </Box>
                  {/* {renderMatchupLine(playerIndex, roundIndex)} */}
                </Box>
              </Box>
            ))}
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography width={180} variant="h6" align="center">Team 2</Typography>
            {team2.map((player, playerIndex) => (
              <Box key={playerIndex} display="flex" flexDirection="column" alignItems="center" mx={2}>
                <Box key={playerIndex} display="flex" flexDirection="column" alignItems="center" my={1}>
                  <Box
                    width={150}
                    height={50}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid black"
                    borderRadius={4}
                    bgcolor='#104a07'
                  >
                    <Typography variant="body1">
                      {player.name}
                    </Typography>
                  </Box>
                  {/* {renderMatchupLine(playerIndex, roundIndex)} */}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box display="flex" justifyContent="center" sx={{ pt: 5 }}>
          {rounds2.map((_, roundIndex) => (
            <Typography width={230} variant="h6" align="center">{getRoundHeader(roundIndex + 1)}</Typography>
          ))}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          {rounds2.map((round, roundIndex) => (
            <Box key={roundIndex} display="flex" flexDirection="column" alignItems="center" mx={2}>
              {round.map((player, playerIndex) => (
                <Box key={playerIndex} display="flex" flexDirection="column" alignItems="center" my={1}>
                  <Box
                    width={200}
                    height={50}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid black"
                    borderRadius={4}
                    bgcolor="background.paper"
                  >
                    <Typography variant="body1">
                      {player.player1.name} vs {player.player2.name}
                    </Typography>
                  </Box>
                  {/* {renderMatchupLine(playerIndex, roundIndex)} */}
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  const renderBracket2 = () => {
    return (
      <Box>
        <Box display="flex" justifyContent="center">
          {rounds.map((_, roundIndex) => (
            <Typography width={180} variant="h6" align="center">{getRoundHeader2(roundIndex)}</Typography>
          ))}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          {rounds.map((round, roundIndex) => (
            <Box key={roundIndex} display="flex" flexDirection="column" alignItems="center" mx={2}>
              {round.map((player, playerIndex) => (
                <Box key={playerIndex} display="flex" flexDirection="column" alignItems="center" my={calculateBracketYDistance(roundIndex)}>
                  <Box
                    width={150}
                    height={50}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid black"
                    borderRadius={4}
                    bgcolor="background.paper"
                  >
                    <Typography variant="body1">
                      {player.name || `Winner of W${roundIndex}-${Math.floor(playerIndex - 1 / 2) + 2}`}
                    </Typography>
                  </Box>
                  {/* {renderMatchupLine(playerIndex, roundIndex)} */}
                </Box>
              ))}
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="center" sx={{ pt: 5 }}>
          <Typography variant="h6" align="center">Losers Bracket</Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          {rounds.map((round, roundIndex) => (
            <Box key={roundIndex} display="flex" flexDirection="column" alignItems="center" mx={2}>
              {round.map((player, playerIndex) => (
                <Box key={playerIndex} display="flex" flexDirection="column" alignItems="center" my={calculateBracketYDistance(roundIndex)}>
                  {roundIndex < 2 ?
                    <Box
                      width={150}
                      height={50}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      // border="1px solid black"
                      borderRadius={4}
                    // bgcolor="background.paper"
                    ></Box> :
                    <Box
                      width={150}
                      height={50}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      border="1px solid black"
                      borderRadius={4}
                      bgcolor="background.paper"
                    >
                      <Typography variant="body1">
                        {player.name || `${roundIndex === 2 ? 'Loser of W' : 'Winner of L'}${roundIndex}-${Math.floor(playerIndex - 1 / 2) + 2}`}
                      </Typography>
                    </Box>}
                  {/* {renderMatchupLine(playerIndex, roundIndex)} */}
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    // <Container maxWidth="lg" sx={{ mt: 2 }}>
    <Grid container spacing={2} sx={{ height: '100%', width: '100%' }}>
      <Grid item xs={12}>
        <Box sx={{ backgroundColor: "background.default", borderRadius: 2, padding: 5 }}>
          <Typography variant="h5" align="center" gutterBottom>First Annual Boys Golf Trip</Typography>
          <List>
            <Paper elevation={1} sx={{ mt: 2, p: 1, backgroundColor: 'background.default' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, textAlign: 'center', backgroundColor: 'background.default', m: 2, borderRadius: 10, p: 5 }}>
                  <Typography variant='h5'>Hello {profile?.nickName}</Typography>
                  {/* {/* </Box> */}
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, textAlign: 'center' }}>
                    {/* <Typography variant={'h6'} sx={{ p: 1 }}>Balance: </Typography> */}
                    <Typography variant={'h6'} color={'green'}>
                      {/* {profile ? profile.wallet.toFixed(2) : ''} $ */}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', m: 2, flex: 1, textAlign: 'center' }}>
                  {/* <BookBankModal/>
                  <BorrowModal/>
                  <LendModal/> */}
                </Box>
                {/* </Grid>
                </Grid> */}
              </Box>
            </Paper>
          </List>
        </Box>
      </Grid>

      {(selectedSport || selectedSpecial) &&
        <Grid item xs={12} md={0}>
          <Box sx={{ backgroundColor: "background.default", borderRadius: 2, padding: 3 }}>
            <Typography sx={{ p: 1 }} variant="h5" gutterBottom align="center">Golf Rounds</Typography>
            {renderEventModule()}
          </Box>
        </Grid>
      }

      <Grid item xs={12} md={0}>
        <Box sx={{ backgroundColor: "background.default", borderRadius: 2, padding: 3 }}>
          <Typography sx={{ p: 1 }} variant="h5" gutterBottom align="center">Tournament Format 1</Typography>
          {renderBracket()}
        </Box>
      </Grid>

      <Grid item xs={12} md={0}>
        <Box sx={{ backgroundColor: "background.default", borderRadius: 2, padding: 3 }}>
          <Typography sx={{ p: 1 }} variant="h5" gutterBottom align="center">Tournament Format 2</Typography>
          {renderBracket2()}
        </Box>
      </Grid>
    </Grid >
  );
};

export default BoysTrip;
