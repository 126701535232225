// src/firebaseConfig.ts
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAEyXQgc8fXTGAx1UUw9XT3Y8kvwtGnBes",
  authDomain: "fantasy-golf-2eae5.firebaseapp.com",
  projectId: "fantasy-golf-2eae5",
  storageBucket: "fantasy-golf-2eae5.appspot.com",
  messagingSenderId: "897520943944",
  appId: "1:897520943944:web:97dca8bb9a62288c29e97d",
  measurementId: "G-7D56WHZ17G"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const functions = getFunctions(app);
const firestore = getFirestore(app);

export { app, auth, functions, analytics, firestore };