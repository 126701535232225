import React, { useState } from 'react';
import { functions } from '../../firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { useAuth } from '../../contexts/AuthContext';
import { Button, CircularProgress, Alert } from '@mui/material';

// const scrapeGolfPlayers = httpsCallable(functions, 'scrapeGolfPlayers');

const GetSportsButton: React.FC = () => {
  const { currentAuthUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  const handleScrape = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);

    if (currentAuthUser) {
      try {
        const response = await fetch('https://us-central1-fantasy-golf-2eae5.cloudfunctions.net/getOdds?endpoint=americanfootball_cfl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await currentAuthUser.getIdToken()}`,
          },
        });
        const result = await response.json();
        setMessage(result.message);
      } catch (error) {
        console.error('Error pulling player odds:', error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error('No user is authenticated');
    }
  };

  return (
    <>
      <Button
        type="button"
        variant="contained"
        fullWidth
        color="primary"
        onClick={handleScrape}
        disabled={loading}
        sx={{ mb: 2 }}>
        {loading ? <CircularProgress size={24} /> : 'Get Sports'}
      </Button>
      {message && <Alert severity="success">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
    </>
  );
};

export default GetSportsButton;