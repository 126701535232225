import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, MenuItem, Select, InputLabel, FormControl, Typography } from '@mui/material';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../firebaseConfig'; // Update with your Firebase config path
import { borderRadius } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../../contexts/AuthContext';
import { getUserNickName } from '../../firestoreServices';
import DynamicSlider from '../DynamicSlider';

const LendModal: React.FC = () => {
  const theme = useTheme();
  const { currentAuthUser, profile, logout } = useAuth();
  const [open, setOpen] = useState(false);

  const [riskTolerance, setRiskTolerance] = useState<number>(0);
  const [transferAmount, setTransferAmount] = useState<number | string>('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmitTransaction = async () => {
    console.log(`Tranfer Sent: Deposit ${transferAmount}.  Set Risk Tolerance to: ${riskTolerance}`)

    // TODO: This should call a backend function that processes money transactions
    // TODO: Then pull profile cash reserve 
    // TODO: Loading until transaction goes through then close window
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (!isNaN(Number(newValue))) {
      setTransferAmount(newValue);
    }
  };

  return (
    <>
      <Button
        size={'large'}
        variant={'contained'}
        // color="primary"
        onClick={() => handleOpen()}
        sx={{ minWidth: 100, mb: 1, color: 'white', backgroundColor: '#104a07' }}
        fullWidth
      >
        Lend (3.00% APY)
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...modalStyle }}>
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant='h4' sx={{ pb: 5 }}>Book Bank</Typography>
            <Typography variant='body1'>
              Lending is quite simple.  Lend your capital to another player and they will pay you a flat 3% APR.
            </Typography>
            {/* <Typography>. . .</Typography>
            <Typography variant='body1'>
              With this in mind, please entertain the opportunity to become the market maker.
              By offering an automated and decentralized solution for users to take advantage of,
              liquidity will increase alongside the provider's earnings.</Typography>
            <Typography>. . .</Typography>
            <Typography variant='body1'>
              The estimated annual return of a market maker is ~10%.
              If you would like to raise the risk tolerance of your portion, you can increase the estimated yield.
              Be ware, increasing the risk above recommended levels is not recommended and can result in being liquidated or going bankrupt.
              Yes! You heard that correctly, you can leverage your Market Maker's liquidity for any number of strategies.
              The risk analysis calculations can be referenced in the wiki.</Typography> */}
          </Box>
          <Box sx={{
            borderRadius: 5,
            backgroundColor: theme.palette.background.default
          }}>
            {/* <DynamicSlider setRiskTolerance={(value: number) => setRiskTolerance(value)} /> */}
            {/* <FormControl sx={{ mt: 2 }}> */}
            {/* <InputLabel>Keyword</InputLabel> */}
            {/* <Select value={keyword} onChange={(e) => setKeyword(e.target.value)}>
              {keywords.map((kw, index) => (
                <MenuItem key={index} value={kw}>{kw}</MenuItem>
              ))}
              <MenuItem value="">Create New Keyword</MenuItem>
            </Select> */}
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingX: "10%",
              paddingBottom: 5,
              paddingTop: 5,
            }}>
              <TextField
                label="Lend Amount"
                value={transferAmount}
                onChange={handleChange}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                sx={{ mt: 0 }}
              />
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Typography fontSize={20} p={2} color='white'>Wallet Balance:</Typography>
                <Typography fontSize={20} color='lime'>{profile ? profile.wallet : 0} $</Typography>
              </Box>
            </Box>
          </Box>
          {/* </FormControl> */}

          <Box sx={{ pt: 5, display: 'flex', justifyContent: 'center' }}>
            {Number(transferAmount) < (profile ? profile.wallet : 0) ? (
              <Button color='success' variant="contained" onClick={handleSubmitTransaction} sx={{ mt: 0 }}>Submit Transaction</Button>) : (
              <Button color='error' variant="outlined" sx={{ mt: 0, backgroundColor: 'black' }}>You're Too Poor</Button>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LendModal;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  borderRadius: 10,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
};
