import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import Home from './pages/Home';
import Signup from './pages/Signup';
import Login from './pages/Login';
import PlayerSelection from './pages/PlayerSelection';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import AdminDashboard from './pages/Admin';
import { useNavigate, useLocation } from 'react-router-dom';
import FeedbackPage from './pages/Feedback';
import TermsAndServices from './pages/TermsAndServices';



const App: React.FC = () => {

  const navigate = useNavigate();
  const { currentAuthUser } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;

    if (currentAuthUser) {
      // Redirect to dashboard only if not already on a protected route
      if (path === '/' || path === '/login' || path === '/signup') {
        navigate('/dashboard');
      }
    } else {
      // Redirect to login only if not already on login or signup page
      if (path !== '/login' && path !== '/signup') {
        navigate('/login');
      }
    }
  }, [currentAuthUser, navigate, location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route element={<PrivateRoute />}>
        <Route path="/player-selection" element={<PlayerSelection />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/feedback" element={<FeedbackPage /> } />
        <Route path="/termsAndServices" element={<TermsAndServices /> } />
      </Route>
      <Route element={<AdminRoute />}>
        <Route path="/admin" element={<AdminDashboard />} />
      </Route>
    </Routes>
  );
};

export default App;