import React, { useState } from 'react';
import { functions } from '../firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { useAuth } from '../contexts/AuthContext';
import { Button, CircularProgress, Alert, TextField, Box } from '@mui/material';
import { fetchSports } from '../firestoreServices';
import { fetchOddsWithCache } from '../oddsData/oddsData';

// const scrapeGolfPlayers = httpsCallable(functions, 'scrapeGolfPlayers');

const GetOddsButton: React.FC = () => {
  const { currentAuthUser } = useAuth();
  const [sport, setSelectedSport] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);


  // const fetchAllSportsOdds = async () => {

  //   const response = await fetchSports();
  //   if (response.success) {
  //     console.log(response.data)
  //     response.data.map((sport: any) => {
  //       const sport_key = sport.key;
  //       const result = await fetchSportOdds(currentAuthUser, sport_key);
  //       setMessage(result.message);
  //     })
  //   }
  // };

  const handleButtonPress = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);

    if (sport === '') {
      setError("Invalid Sport Key")
      setLoading(false);
      return
    }

    try {
        // Usage
        await fetchOddsWithCache(currentAuthUser, sport).then(response => {
        console.log('Response:', response);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
      // await fetchAllSportsOdds();
      // const result = await fetchSportOdds(currentAuthUser, sport);
    } catch (error) {
      console.error('Error fetching all Sports Odds data:', error);
    } finally {
      setLoading(false);
    }
    // if (currentAuthUser) {
    //   try {
    //     const response = await fetch(`https://us-central1-fantasy-golf-2eae5.cloudfunctions.net/getOdds?endpoint=${sport}`, {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${await currentAuthUser.getIdToken()}`,
    //       },
    //     });
    //     const result = await response.json();
    //     setMessage(result.message);
    //   } catch (error) {
    //     console.error('Error pulling sport odds:', error);
    //     setError('Error pulling sport odds:' + error)
    //   } finally {
    //     setLoading(false);
    //   }
    // } else {
    //   console.error('No user is authenticated');
    // }
  };

  return (
    <Box>
      <TextField
        margin="normal"
        required
        fullWidth
        id="sport_key"
        label="Sport Key"
        name="sport_key"
        autoFocus
        value={sport}
        onChange={(e) => setSelectedSport(e.target.value)}
      />
      <Button
        type="button"
        variant="contained"
        fullWidth
        color="primary"
        onClick={handleButtonPress}
        disabled={loading}
        sx={{ mb: 2 }}>
        {loading ? <CircularProgress size={24} /> : 'Get Odds'}
      </Button>
      {message && <Alert severity="success">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
    </Box>
  );
};

export default GetOddsButton;