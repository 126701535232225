import React from 'react';
import { Slider, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const CustomSlider = styled(Slider)(({ theme }) => ({
  height: 8,
  '& .MuiSlider-track': {
    height: 8,
    opacity: 0,
    // borderRadius: 4,
    background: 'none',
  },
  '& .MuiSlider-rail': {
    height: 8,
    // borderRadius: 4,
    opacity: 0,
    background: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 25,
    width: 25,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
}));

interface DynamicSliderProps {
  setRiskTolerance: (riskTolerance: number) => void
}

const DynamicSlider: React.FC<DynamicSliderProps> = ({ setRiskTolerance }) => {
  const [value, setValue] = React.useState<number>(50);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
    setRiskTolerance(newValue as number);
  };

  return (
    <Box sx={{ width: '80%', margin: 'auto', padding: '50px 0' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
        <Typography gutterBottom>Risk Tolerance</Typography>
        <Typography>{value}%</Typography>
      </Box>
      <Box
        sx={{
          p: 1,
          position: 'relative',
          height: 8,
          borderRadius: 6,
          background: `linear-gradient(to right, green ${50}%, orange ${70}%, red ${90}%)`,
        }}
      >
        <CustomSlider
          value={value}
          onChange={handleSliderChange}
          aria-labelledby="continuous-slider"
          sx={{
            position: 'absolute',
            top: -8,
            left: 0,
            right: 0,
          }}
        />
      </Box>
    </Box>
  );
};

export default DynamicSlider;
