import React, { useState, useEffect } from 'react';
import { Container, Box, TextField, Button, Typography, Alert, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { submitFeedback, getFeedbacks, submitResponse, upvoteFeedback, downvoteFeedback, getUserNickName } from '../firestoreServices';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { Feedback } from '../types/Types';

const FeedbackPage: React.FC = () => {
  const navigate = useNavigate();
  const { currentAuthUser } = useAuth();
  const [feedback, setFeedback] = useState('');
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [nicknames, setNicknames] = useState<{ [key: string]: string }>({});
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [responses, setResponses] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchFeedbacks = async () => {
      const feedbackList = await getFeedbacks();
      feedbackList.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
      setFeedbacks(feedbackList);

      const nicknameMap: { [key: string]: string } = {};
      for (const feedback of feedbackList) {
        const nickname = await getUserNickName(feedback.userId);
        nicknameMap[feedback.userId] = nickname;
        for (const response of feedback.responses) {
          const responseNickname = await getUserNickName(response.userId);
          nicknameMap[response.userId] = responseNickname;
        }
      }
      setNicknames(nicknameMap);
    };
    fetchFeedbacks();
  }, []);

  const handleSubmit = async () => {
    if (feedback.trim() === '') {
      setError('Feedback cannot be empty.');
      return;
    }

    try {
      const newFeedback = {
        id: '0',
        content: feedback,
        userId: currentAuthUser.uid,
        timestamp: new Date(),
        upvotes: 0,
        downvotes: 0,
        responses: []
      } as Feedback;
      const feedbackId = await submitFeedback(feedback, currentAuthUser.uid);
      newFeedback.id = feedbackId;
      setFeedbacks([newFeedback, ...feedbacks]);
      setMessage('Feedback submitted successfully!');
      setFeedback('');
      setError('');

      const nickname = await getUserNickName(currentAuthUser.uid);
      setNicknames({ ...nicknames, [currentAuthUser.uid]: nickname });
    } catch (err) {
      setError('Failed to submit feedback. Please try again.');
      setMessage('');
    }
  };

  const handleResponseSubmit = async (feedbackId: string) => {
    const response = responses[feedbackId];
    if (response.trim() === '') {
      setError('Response cannot be empty.');
      return;
    }

    try {
      await submitResponse(feedbackId, response, currentAuthUser.uid);
      setFeedbacks(feedbacks.map(fb => {
        if (fb.id === feedbackId) {
          return { ...fb, responses: [...fb.responses, { content: response, userId: currentAuthUser.uid, timestamp: new Date() }] };
        }
        return fb;
      }));
      setResponses({ ...responses, [feedbackId]: '' });
      setMessage('Response submitted successfully!');
      setError('');
    } catch (err) {
      setError('Failed to submit response. Please try again.');
      setMessage('');
    }
  };

  const handleUpvote = async (feedbackId: string) => {
    try {
      await upvoteFeedback(feedbackId);
      setFeedbacks(feedbacks.map(fb => fb.id === feedbackId ? { ...fb, upvotes: fb.upvotes + 1 } : fb));
    } catch (err) {
      setError('Failed to upvote feedback. Please try again.');
    }
  };

  const handleDownvote = async (feedbackId: string) => {
    try {
      await downvoteFeedback(feedbackId);
      setFeedbacks(feedbacks.map(fb => fb.id === feedbackId ? { ...fb, downvotes: fb.downvotes + 1 } : fb));
    } catch (err) {
      setError('Failed to downvote feedback. Please try again.');
    }
  };

  const handleResponseChange = (feedbackId: string, response: string) => {
    setResponses({ ...responses, [feedbackId]: response });
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
        <Button 
          variant="outlined" 
          color="primary" 
          onClick={() => navigate('/dashboard')} 
          sx={{ position: 'absolute', top: 0, right: 0 }}
        >
          Back
        </Button>
        <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
          Feedback Forum
        </Typography>
        {message && <Alert severity="success">{message}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <TextField
            fullWidth
            label="Feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            sx={{ mr: 2, width: '90%' }}
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Start New Thread
          </Button>
        </Box>
        <List sx={{ width: '100%', mt: 4 }}>
          {feedbacks.map((fb) => (
            <ListItem key={fb.id} alignItems="flex-start" sx={{ mb: 2, border: '1px solid grey', borderRadius: 2, display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    component="span"
                    variant="body1"
                    color="textPrimary"
                    sx={{ mt: 1 }}
                  >
                    {fb.content}
                  </Typography>
                  <Typography
                    component="span"
                    variant="caption"
                    color="textSecondary"
                    sx={{m:1}}
                  >
                    {nicknames[fb.userId]} — {new Date(fb.timestamp).toLocaleString()}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton edge="end" aria-label="upvote" onClick={() => handleUpvote(fb.id)} sx={{ marginRight: 1 }}>
                    <ThumbUpIcon />
                  </IconButton>
                  <Typography variant="body2" component="span" sx={{ marginRight: 2 }}>
                    {fb.upvotes}
                  </Typography>
                  <IconButton edge="end" aria-label="downvote" onClick={() => handleDownvote(fb.id)} sx={{ marginRight: 1 }}>
                    <ThumbDownIcon />
                  </IconButton>
                  <Typography variant="body2" component="span">
                    {fb.downvotes}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <TextField
                  // fullWidth
                  label="Comment"
                  value={responses[fb.id] || ''}
                  onChange={(e) => handleResponseChange(fb.id, e.target.value)}
                  sx={{ width: '90%' }}
                />
                <Button variant="contained" color="primary" sx={{m:1}} onClick={() => handleResponseSubmit(fb.id)}>
                  Submit
                </Button>
              </Box>
              <Box sx={{ mt: 2, width: '100%' }}>
                {fb.responses.map((response, index) => (
                  <Box key={index} sx={{ ml: 4, mt: 2, p: 2, border: '1px solid grey', borderRadius: 2 }}>
                    <Typography variant="body2"  sx={{mb:1}}>{response.content}</Typography>
                    <Typography variant="caption" sx={{m:1}} color="textSecondary">
                      {nicknames[response.userId]} — {new Date(response.timestamp).toLocaleString()}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default FeedbackPage;
