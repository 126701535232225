import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, MenuItem, Select, InputLabel, FormControl, Typography } from '@mui/material';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../firebaseConfig'; // Update with your Firebase config path
import { borderRadius } from '@mui/system';
import { useAuth } from '../../contexts/AuthContext';
import { getUserNickName } from '../../firestoreServices';

const SubmitLinesModal: React.FC = () => {
  const { currentAuthUser, profile, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [newKeyword, setNewKeyword] = useState('');
  const [event, setEvent] = useState('');

  // New Event Contents
  const [newEventTitle, setNewEventTitle] = useState('');
  const [newEventHomeTeam, setNewEventHomeTeam] = useState('');
  const [newEventAwayTeam, setNewEventAwayTeam] = useState('');
  const [newEventCommenceTime, setNewEventCommenceTime] = useState('');
  const [newEventDescription, setNewEventDescription] = useState('');
  const [newEventLocation, setNewEventLocation] = useState('');


  const [market, setMarket] = useState('');
  const [markets, setMarkets] = useState([{ key: '', name: '', price: '', point: '' }]);

  const [keywords, setKeywords] = useState<string[]>([]);
  const [events, setEvents] = useState<any[]>([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // Fetch existing keywords and events
    const fetchKeywordsAndEvents = async () => {
      const keywordsSnapshot = await getDocs(collection(firestore, 'odds/specials/keywords'));
      const eventsSnapshot = await getDocs(collection(firestore, 'odds/specials/events'));
      setKeywords(keywordsSnapshot.docs.map(doc => doc.data().title));
      setEvents(eventsSnapshot.docs.map(doc => doc.data()));
    };

    fetchKeywordsAndEvents();
  }, [keyword]);

  const handleMarketChange = (index: number, field: keyof typeof markets[0], value: string) => {
    const newMarkets = [...markets];
    newMarkets[index][field] = value;
    setMarkets(newMarkets);
  };

  const addMarket = () => {
    setMarkets([...markets, { key: market, name: '', price: '', point: '' }]);
  };

  const handleSubmitEvent = async () => {


    let selectedKeyword = keyword || newKeyword;

    if (newEventTitle && newEventHomeTeam) {
      try {
        await addDoc(collection(firestore, 'odds/specials/events'), {
          title: newEventTitle,
          keyword: selectedKeyword,
          home_team: newEventHomeTeam,
          away_team: newEventAwayTeam,
          commence_time: newEventCommenceTime,
          description: newEventDescription,
          location: newEventLocation,
        });
      } catch {
        console.log("Error Submitting New Event")
        return
      }

      setEvent(newEventTitle)

    }
  };

  const handleSubmit = async () => {
    try {
      let selectedKeyword = keyword || newKeyword;
      let selectedEvent = event;

      if (newKeyword) {
        await addDoc(collection(firestore, 'odds/specials/keywords'), { title: newKeyword });
      }

      // if (newEventTitle && newEventHomeTeam) {
      //   await addDoc(collection(firestore, 'odds/specials/events'), {
      //     title: newEventTitle,
      //     keyword: selectedKeyword,
      //     home_team: newEventHomeTeam,
      //     away_team: newEventAwayTeam,
      //     commence_time: newEventCommenceTime,
      //     description: newEventDescription,
      //     location: newEventLocation,
      //   });
      // }

      const eventsPath = market === "outRight" ? 'odds/specials/outRights' : 'odds/specials/events';
      const eventsRef = collection(firestore, eventsPath);
      const q = query(eventsRef, where('title', '==', selectedEvent));
      const querySnapshot = await getDocs(q);
      let eventId: string;

      if (!querySnapshot.empty) {
        eventId = querySnapshot.docs[0].id;
      } else {
        const newEventRef = await addDoc(eventsRef, { title: selectedEvent, keyword: selectedKeyword, submittedBy: currentAuthUser.uid, eventCreated: new Date() });
        eventId = newEventRef.id;
      }

      // console.log("BREAKPOINT")

      const bookmakerRef = collection(firestore, `${eventsPath}/${eventId}/bookmakers`);
      const bookmakerDocRef = await addDoc(bookmakerRef, {
        key: currentAuthUser.uid,
        name: await getUserNickName(currentAuthUser.uid),
        last_updated: new Date(),
        title: selectedEvent,
      });

      // console.log("BREAKPOINT2")

      const outcomes = markets.map(market => ({
        name: market.name ? market.name : "UNDEFINED",
        price: market.price ? parseFloat(market.price) : 0,
        point: market.point ? parseFloat(market.point) : 0
      }));
  
      await addDoc(collection(firestore, `${eventsPath}/${eventId}/bookmakers/${bookmakerDocRef.id}/markets`), {
        key: market,
        outcomes
      });

      handleClose();
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#521841',
          color: 'white',
          whiteSpace: 'nowrap',
          paddingX: 10, // Adjust padding as needed
          paddingY: 2,
          fontSize: '15px',  // Adjust font size if needed
          minWidth: '150px', // Adjust min-width as needed
          maxWidth: 'fit-content',
        }}
        onClick={handleOpen}
      >
        Submit New Lines
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...modalStyle }}>
          <Box sx={{ p: 2 }}>
            <Typography>Line Builder</Typography>
          </Box>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Keyword</InputLabel>
            <Select value={keyword} onChange={(e) => setKeyword(e.target.value)}>
              {keywords.map((kw, index) => (
                <MenuItem key={index} value={kw}>{kw}</MenuItem>
              ))}
              <MenuItem value="">Create New Keyword</MenuItem>
            </Select>
            {!keyword && (
              <TextField
                fullWidth
                label="New Keyword"
                value={newKeyword}
                onChange={(e) => setNewKeyword(e.target.value)}
                sx={{ mt: 2 }}
              />
            )}
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Event</InputLabel>
            <Select value={event} onChange={(e) => setEvent(e.target.value)}>
              {events.filter(ev => ev.keyword === keyword).map((ev, index) => (
                <MenuItem key={index} value={ev.title}>{ev.title}</MenuItem>
              ))}
              <MenuItem value="Create New Event">Create New Event</MenuItem>
            </Select>
            {event === "Create New Event" && (
              <Box>
                <TextField
                  fullWidth
                  label="Event Title"
                  value={newEventTitle}
                  onChange={(e) => setNewEventTitle(e.target.value)}
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  label="Home Team"
                  value={newEventHomeTeam}
                  onChange={(e) => setNewEventHomeTeam(e.target.value)}
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  label="Away Team"
                  value={newEventAwayTeam}
                  onChange={(e) => setNewEventAwayTeam(e.target.value)}
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  label="Commence Time"
                  value={newEventCommenceTime}
                  onChange={(e) => setNewEventCommenceTime(e.target.value)}
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  label="Description"
                  value={newEventDescription}
                  onChange={(e) => setNewEventDescription(e.target.value)}
                  sx={{ mt: 2 }}
                />
                <TextField
                  fullWidth
                  label="Location"
                  value={newEventLocation}
                  onChange={(e) => setNewEventLocation(e.target.value)}
                  sx={{ mt: 2 }}
                />
                <Box sx={{ pt: 4, display: 'flex', justifyContent: 'center' }}>
                  <Button variant="contained" color="primary" onClick={handleSubmitEvent} sx={{ mt: 0 }}>Submit Event</Button>
                </Box>
              </Box>
            )}

          </FormControl>

          {event && event != "Create New Event" && <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Lines Type</InputLabel>
            <Select value={market} onChange={(e) => setMarket(e.target.value)}>
              <MenuItem value="outRight">Out Right Winners</MenuItem>
              <MenuItem value="mlSpreadTots">MoneyLine/Spread/Total</MenuItem>
            </Select>
          </FormControl>}

          {event && market != "" && markets.map((line, index) => (
            <Box key={index} sx={{ mt: 2 }}>
              {market === "mlSpreadTots" &&
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel>Lines Type</InputLabel>
                  <Select value={markets[index]['key']} onChange={(e) => { handleMarketChange(index, 'key', e.target.value) }}>
                    <MenuItem value="h2h">Money Line</MenuItem>
                    <MenuItem value="spreads">Spread</MenuItem>
                    <MenuItem value="totals">Total</MenuItem>
                  </Select>
                </FormControl>
              }
              {markets[index]['key'] != "totals" ? (
                <TextField
                  fullWidth
                  label="Winner Name"
                  value={markets[index].name}
                  onChange={(e) => handleMarketChange(index, 'name', e.target.value)}
                  sx={{ mt: 2 }}
                />)
                : (
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    {/* <InputLabel>Lines Type</InputLabel> */}
                    <Select value={markets[index]['name']} onChange={(e) => { handleMarketChange(index, 'name', e.target.value) }}>
                      {/* {events.map((ev, index) => (
                  <MenuItem key={index} value={ev}>{ev}</MenuItem>
                ))} */}
                      <MenuItem value="Over">Over</MenuItem>
                      <MenuItem value="Under">Under</MenuItem>
                    </Select>
                  </FormControl>
                )}
              <TextField
                fullWidth
                label="Odds"
                value={markets[index].price}
                onChange={(e) => handleMarketChange(index, 'price', e.target.value)}
                sx={{ mt: 2 }}
              />
              {market === "mlSpreadTots" && (line.key === 'spreads' || line.key === 'totals') && (
                <TextField
                  fullWidth
                  label="Points"
                  value={markets[index].point}
                  onChange={(e) => handleMarketChange(index, 'point', e.target.value)}
                  sx={{ mt: 2 }}
                />
              )}
            </Box>
          ))}

          {event && event != "Create New Event" && market && <Button variant="outlined" onClick={addMarket} sx={{ mt: 2 }}>Add Another Line</Button>}
          {event && event != "Create New Event" && market && <Box sx={{ pt: 10, display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 0 }}>Submit</Button>
          </Box>}
        </Box>
      </Modal>
    </>
  );
};

export default SubmitLinesModal;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  borderRadius: 10,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
};
