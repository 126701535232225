import React, { useState } from 'react';
import { Container, Box, Typography, MenuItem, Select, FormControl, InputLabel, Grid, Avatar, SelectChangeEvent, Button, ListItem, styled } from '@mui/material';
import { Team, Player } from '../types/Types';
import { useNavigate } from 'react-router-dom';

interface MyTeamProps {
  team: Team | undefined;
}

const TeamItem = styled(ListItem)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#142b16',
  padding: '10px 20px',
  borderRadius: '8px',
  margin: '8px 0',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.02)',
  },
});

const timeStampToTime = (timeStamp: number) => {
  const date = new Date(timeStamp);
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedTime = `${hours}:${minutes} ${ampm}`;
  return formattedTime;
}

const MyTeam: React.FC<MyTeamProps> = ({ team }) => {
  const maxTotalScore = team ? Math.max(...team.players.map(player => player.totalSort)) : 0;


  return (
    <Container component="main" maxWidth="lg" sx={{ mt: 3 }}>

      {team &&
        <Box>
          <Grid container spacing={2}>
            {team.players.map((player: Player) => (
              <Grid item xs={12} sm={6} md={4} lg={2} key={player.id}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: player.totalSort === maxTotalScore ? '#750e0e' : '#1b3b1e', // Highlight highest total score in red
                    padding: 2,
                    borderRadius: 2,
                    boxShadow: 3,
                    textAlign: 'center',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  {/* <Avatar
                    alt={player.name}
                    src={player.profilePictureUrl}
                    sx={{ width: 56, height: 56, mb: 2 }}
                  /> */}
                  {true ? (
                    <>
                  <Typography variant="h6" noWrap>
                    {player.displayName}
                  </Typography>
                  <Typography variant="body2">Total: {player.totalSort > 0 ? "+" + player.totalSort : player.totalSort}</Typography>
                  <Typography variant="body2">Position: {player.position}</Typography>
                  <Typography variant="body2">Rank: {player.rank}</Typography>
                  <Typography variant="body2">W Odds: {player.oddsToWin}</Typography>
                  <Typography variant="body2">Thru: {player.thru === "" ? timeStampToTime(player.teeTime) : player.thru}</Typography>
                  </>
                  ) : (
                    <Typography variant='body2'>-</Typography>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      }
    </Container>
  );
};

export default MyTeam;
