// src/makeAdmin.js
import { functions } from './firebaseConfig';
import { httpsCallable } from 'firebase/functions';

const addAdminRole = httpsCallable(functions, 'addAdminRole');

const makeAdmin = async (email) => {
  try {
    const result = await addAdminRole({ email });
    return result.data.message;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default makeAdmin;