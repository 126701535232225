// src/pages/TermsAndServices.tsx
import React from 'react';
import { Container, Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const TermsAndServices: React.FC = () => {
  const navigate = useNavigate();

  const handleBack = async () => {
    navigate('/dashboard');
  };

  return (
    <Container component="main" maxWidth="md">
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <Box sx={{ width: '100%', display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Typography variant="h5" gutterBottom>
          Terms & Services
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>Effective Date:</strong> Jun 20, 2024
        </Typography>

        
          <Button variant="contained" color="primary" onClick={handleBack}>
            Back To Dash
          </Button>
        </Box>

        <Typography variant="body1" align="left" paragraph>
          <strong>1. Introduction</strong><br />
          Welcome to WashedUp. By accessing or using our Platform, you agree to comply with and be bound by these Terms of Service and User Agreement ("Agreement"). If you do not agree to these terms, please do not use our Platform.
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>2. Nature of Service</strong><br />
          WashedUp provides a simulated sports betting experience using virtual currency ("Virtual Money"). No real money is involved, and users cannot convert Virtual Money into real money or other valuable rewards. This Platform is intended for entertainment and educational purposes only.
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>3. Eligibility</strong><br />
          To use our Platform, you must be at least 18 years old or the age of majority in your jurisdiction, whichever is greater. By using the Platform, you represent and warrant that you meet these eligibility requirements.
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>4. Account Registration</strong><br />
          To access certain features of the Platform, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>5. Use of Virtual Money</strong><br />
          - Virtual Money is used solely for entertainment within the Platform.<br />
          - Virtual Money has no real-world value and cannot be exchanged for real currency or other items of value.<br />
          - Any attempt to sell, transfer, or exchange Virtual Money outside the Platform is strictly prohibited.
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>6. User Conduct</strong><br />
          You agree to use the Platform in accordance with all applicable laws and regulations. You shall not:<br />
          - Use the Platform for any illegal purpose.<br />
          - Attempt to gain unauthorized access to the Platform or other user accounts.<br />
          - Use the Platform to transmit any harmful or malicious code.<br />
          - Engage in any activity that interferes with or disrupts the Platform.
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>7. Intellectual Property</strong><br />
          All content, features, and functionality on the Platform, including but not limited to text, graphics, logos, and software, are the exclusive property of WashedUp or its licensors and are protected by intellectual property laws.
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>8. Privacy</strong><br />
          Your use of the Platform is also governed by our Privacy Policy, which can be found [insert link to Privacy Policy]. By using the Platform, you consent to the collection, use, and sharing of your information as described in our Privacy Policy.
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>9. Disclaimer of Warranties</strong><br />
          The Platform is provided on an "as is" and "as available" basis. WashedUp makes no warranties, expressed or implied, regarding the Platform's operation or your use of the Platform.
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>10. Limitation of Liability</strong><br />
          To the maximum extent permitted by law, WashedUp shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Platform.
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>11. Indemnification</strong><br />
          You agree to indemnify and hold harmless WashedUp, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of this Agreement or your use of the Platform.
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>12. Changes to the Agreement</strong><br />
          WashedUp reserves the right to modify or update this Agreement at any time. We will notify you of any changes by posting the new Agreement on the Platform. Your continued use of the Platform following the posting of changes constitutes your acceptance of such changes.
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>13. Governing Law</strong><br />
          This Agreement shall be governed by and construed in accordance with the laws of the state of [Your State], without regard to its conflict of law principles.
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>14. Contact Information</strong><br />
          If you have any questions about this Agreement, please contact us at [Your Contact Information].
        </Typography>

        <Typography variant="body1" align="left" paragraph>
          <strong>Acceptance of Terms</strong><br />
          By clicking "I Agree" or by accessing or using the Platform, you acknowledge that you have read, understood, and agree to be bound by this Agreement.
        </Typography>

        <Box sx={{ width: '100%', display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center', pt: 5, pb: 20}}>
          <Button variant="contained" color="primary" onClick={handleBack}>
            Back To Dash
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default TermsAndServices;
