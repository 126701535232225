import { Result } from "../types/Types";


const cacheExpiry = 2 * 60 * 1000; // 2 minutes in milliseconds

const fetchSportOdds = async (currentAuthUser:any, sport_key: string): Promise<Result<string>> => {
  if (currentAuthUser) {
    try {
      const response = await fetch(`https://us-central1-fantasy-golf-2eae5.cloudfunctions.net/getOdds?endpoint=${sport_key}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await currentAuthUser.getIdToken()}`,
        },
      });
      const result = await response.json();
      return { success: true, data: result}
    } catch (error) {
      console.error('Error pulling player odds:', error);
      return { success: false, error: 'Error pulling player odds:' + error}
    }
  } else {
    console.error('No user is authenticated');
    return { success: false, error: 'No user is authenticated'}
  }
};

export const fetchOddsWithCache = async (currentAuthUser: any, sportKey: string) => {
  const cacheKey = `odds_${sportKey}`;
  const cachedData = localStorage.getItem(cacheKey);
  const cacheTime = localStorage.getItem(`${cacheKey}_time`);
  const now = Date.now();

  if (cachedData && cacheTime && now - parseInt(cacheTime, 10) < 2 * 60 * 1000) {
    return JSON.parse(cachedData);
  }

  const response = await fetchSportOdds(currentAuthUser, sportKey);
  if (response.success) {
    localStorage.setItem(cacheKey, JSON.stringify(response.data));
    localStorage.setItem(`${cacheKey}_time`, now.toString());
    return response.data;
  }
  return 

  
};

