// src/components/AdminRoute.tsx
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { CircularProgress, Box } from '@mui/material';
import { IdTokenResult } from 'firebase/auth';
import { useAdmin } from '../contexts/AdminContext';

const AdminRoute: React.FC = () => {
  const { currentAuthUser } = useAuth();
  const { isAdmin } = useAdmin();

  if (isAdmin === null) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!isAdmin) {
    // console.log('User is not an admin, redirecting to home.');
    return <Navigate to="/" />;
  }

  // console.log('User is an admin, rendering admin content.');
  return <Outlet />;
};

export default AdminRoute;