import React, { useEffect, useState } from "react";
import { Member, Team } from "../types/Types";
import { Box, List, ListItem, ListItemText, Typography, Avatar, Collapse, useMediaQuery, useTheme } from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { styled } from '@mui/system';
import { getOwners } from "../firestoreServices";
import MyTeam from "./MyTeam";

interface LeaderboardProps {
  teams: Team[];
  owners: Member[];
}

const TeamItem = styled(ListItem)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#142b16',
  padding: '10px 20px',
  borderRadius: '8px',
  margin: '8px 0',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.02)',
  },
});

const MoneyBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100px', // Set a fixed width for the money box
  height: '50px', // Set a fixed height for the money box
  [theme.breakpoints.down('sm')]: {
    width: '40px',
    height: '40px',
  },
}));

const PositionAvatar = styled(Avatar)(({ position }: { position: number }) => ({
  backgroundColor: position === 0 ? '#ffcc00' : position === 1 ? '#a9a9a9' : position === 2 ? '#cd7f32' : '#c0c0c0',
  color: '#1e1e1e',
  marginRight: '15px',
}));

const Leaderboard: React.FC<LeaderboardProps> = ({ teams, owners }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openTeam, setOpenTeam] = useState<string | null>(null);

  const handleToggle = (teamId: string) => {
    setOpenTeam(openTeam === teamId ? null : teamId);
  };



  return (
    <List>
      {teams.map((team: Team, index: number) => (
        <Box key={team.id}>
          <TeamItem onClick={() => handleToggle(team.id)}>
            <Box display="flex" alignItems="center" justifyContent="flex-start" width='100%' flexDirection={isMobile ? 'row' : 'row'}>
              <PositionAvatar position={index}>
                {index + 1}
              </PositionAvatar>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', flexDirection: isMobile ? 'column' : 'row' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: isMobile ? '100%' : '50%', flexDirection: isMobile ? 'column' : 'row', mb: isMobile ? 1 : 0 }}>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="h6"
                    color="text.primary"
                  >
                    {team.name}
                  </Typography>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body1"
                    color="text.primary"

                  >
                    Score: {team.total_score > 0 ? "+" + team.total_score : team.total_score}
                  </Typography>
                </Box>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body1"
                  color="text.primary"

                >
                  Holes Left Today: {team.holes_left}
                </Typography>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body1"
                  color="text.primary"
                >
                  Manager: {owners.find(owner => owner.id === team.owner_id)?.nickName ?? ' who dis'}
                </Typography>
              </Box>
              {!isMobile &&
                <MoneyBox>
                  <AttachMoneyIcon style={{ color: index in [0, 1, 2] ? '#ffcc00' : '#ffffff55' }} />
                  {index in [0, 1] ? <AttachMoneyIcon style={{ color: index in [0, 1] ? '#ffcc00' : '#ffffff55' }} /> : <></>}
                  {index === 0 ? <AttachMoneyIcon style={{ color: index in [0, 1] ? '#ffcc00' : '#ffffff55' }} /> : <></>}
                </MoneyBox>
              }
            </Box>
          </TeamItem>
          <Collapse in={openTeam === team.id}>
            <Box sx={{ pl: 2, pb: 3 }}>
              <MyTeam team={team} /> {/* Pass only the selected team */}
            </Box>
          </Collapse>
        </Box>
      ))}
    </List>
  );
};




export default Leaderboard;