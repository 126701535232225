// src/pages/Dashboard.tsx
import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Tabs, Tab, List, ListItem, ListItemText, Button, Checkbox, Alert, useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Team, Member } from '../types/Types';
import { useAdmin } from '../contexts/AdminContext';
import { useAuth } from '../contexts/AuthContext';
import { getTeams, getOwners } from '../firestoreServices';
import Leaderboard from './Leaderboard';
import MyTeam from './MyTeam';
import PoolEntries from './PoolEntries';
import { Teams } from './Teams';
import BettingExchange from '../components/casino/BettingExchange';
import Casino from '../components/casino/Casino';
import BoysTrip from './BoysTrip';
import FantasyFootball from './FantasyFootball';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const { currentAuthUser, profile, logout } = useAuth();
  const { isAdmin } = useAdmin();

  const [teams, setTeams] = useState<Team[]>([]);
  const [owners, setOwners] = useState<Member[]>([]);
  // const [userTeams, setUserTeams] = useState<Team[]>([]);
  const [hasTeam, setHasTeam] = useState<Boolean>(false);

  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');

  const casinoAccess = [""]

  useEffect(() => {
    // console.log(currentAuthUser.uid)
    const fetchTeams = async () => {
      try {
        const result = await getTeams();
        if (!result.success) {
          setError(result.error)
          return;
        }
        const teamsList = result.data;
        teamsList.sort((a, b) => a.total_score - b.total_score);
        setTeams(teamsList);

        // console.log(teamsList)
        const userTeams = teamsList.filter(team => team.owner_id === currentAuthUser.uid);
        // setUserTeams(userTeams);
        if (userTeams.length > 0) {
          setHasTeam(false);
        } else {
          setHasTeam(true);
        }
      } catch (err) {
        // console.error("Error fetching teams:", err);
        setError("Failed to fetch teams.");
      }
    }

    fetchTeams();
  }, [currentAuthUser]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const result = await getOwners()
        if (!result.success) {
          setError(result.error)
          return;
        }
        const ownersList = result.data;
        // ownersList.sort((a, b) => a.name.localeCompare(b.name));
        setOwners(ownersList);
      } catch (err) {
        console.error("Error fetching teams:", err);
        setError("Failed to fetch teams.");
      }
    };

    fetchMembers();
  }, [currentAuthUser]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleFeedBack = () => {
    navigate('/feedback');
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleTermsAndServices = () => {
    navigate('/termsAndServices');
  };

  const handlePickTeam = () => {
    navigate('/player-selection');
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isMobile)
      setError("This Website is Not Built For Mobile.  If you must, try zooming all the way out")
    else
      setError("")
  }, [isMobile]);


  return (
    // <Container component="main" maxWidth="lg" sx={{ mt: 0, justifyContent: 'flex-end' }}>
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        overflow: 'auto',
        scrollbarWidth: 'none',
        // mt: 10,
        p: 10
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '60px' }}>
        {isAdmin ? (
          <Button
            type="button"
            variant="outlined"
            onClick={() => navigate('/admin')}
          >
            Admin
          </Button>
        ) : (
          <Box sx={{ width: '120px' }} /> // Placeholder box with same width as button
        )}
        <Button type="button" variant="outlined" onClick={handleFeedBack} sx={{ ml: 2 }}>
          FeedBack
        </Button>
        <Button type="button" variant="outlined" onClick={handleLogout} sx={{ ml: 2 }}>
          Logout
        </Button>
        <Button type="button" variant="outlined" onClick={handleTermsAndServices} sx={{ ml: 2 }}>
          Terms & Services
        </Button>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'background.paper',
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          mt: 3,
          mb: 3,
        }}
      >
        <Typography component="h1" variant="h3" gutterBottom p={3}>
          Washed Up
        </Typography>
        {message && <Alert sx={{ mb: 2 }} severity="success">{message}</Alert>}
        {error && <Alert sx={{ mb: 2 }} severity="error">{error}</Alert>}
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="dashboard tabs"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            '& .MuiTabs-flexContainer': {
              justifyContent: 'center',
            },
            '& .MuiTab-root': {
              minWidth: 'auto',
              textTransform: 'none',
              fontSize: '1rem',
            },
          }}
        >
          <Tab label="Fantasy Football" />
          <Tab label="Fantasy Golf" />
          {/* <Tab label="Teams" /> */}
          <Tab label="Betting Exchange" />
          <Tab label="Boys Trip" />
          <Tab label="Casino" />
        </Tabs>
        <Box sx={{ width: '100%', mt: 2 }}>
        {tabIndex === 0 && (profile && <FantasyFootball />)}
          {tabIndex === 1 && (
            <Box sx={{ mt: 3 }}>
              <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Typography variant="h5">The Open Championship</Typography>
                {hasTeam &&
                  <Button
                    type="button"
                    // fullWidth
                    variant="contained"
                    sx={{ my: 3 }}
                    onClick={handlePickTeam}
                  >
                    Create A Team
                  </Button>}
              </Box>
              <Leaderboard teams={teams} owners={owners}></Leaderboard>
            </Box>
          )}
          {/* {tabIndex === 1 && (
            <Teams teams={teams}></Teams>
          )} */}
          {/* {tabIndex === 2 && (
            <Box>
              <PoolEntries owners={owners}></PoolEntries>
            </Box>
          )} */}
          {tabIndex === 2 && (profile && <BettingExchange owners={owners} />)}
          {tabIndex === 3 && (profile && <BoysTrip />)}
          {tabIndex === 4 && (
            <Box>
              {casinoAccess.includes(currentAuthUser.uid) ?
                (profile && <Casino />) :
                (
                  <>
                    <Typography variant="h6">Casino</Typography>
                    <Typography variant="h6">Coming Soon...</Typography>
                    <iframe
                      width="100%"
                      height="700"
                      src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded Video"
                    ></iframe>
                  </>
                )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
    // /* </Container> */
  );
};

export default Dashboard;
