// src/components/BettingExchange.tsx
import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, TextField, Button, List, ListItem, ListItemText, Divider, Paper, Avatar, Grid, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, ToggleButtonGroup, ToggleButton, Collapse } from '@mui/material';
import { fetchOdds, fetchSports, fetchSpecial, fetchSpecials, fetchWeddingFutures, getAllLiveBets, getOpenBets, getAllOpenBets, getSettledBets, offerBet, takeBet, updateUser, fetchSpecialsKeyWords } from '../../firestoreServices';
import { LiveOrder, Line, Market, Member, MoneyLine, OpenOrder, Sport, SportsEventOdds, Spread, Total, OrderType, OrderSide, PendingOrder, SettledOrder, SportsEvent } from '../../types/Types';
import { useAuth } from '../../contexts/AuthContext';
import FinnHub from '../FinnHub';
import FinnHubWebSocket from '../FinnHub';
import BidAskChart from './BidAskChart';
import { calculateLayOdds, calculateOpenContractPrice, getPrimaryText, getSecondaryText, market2Text } from './utils';
import SubmitLinesModal from '../modals/SubmitLinesModal';
import BookBankModal from '../modals/BookBankModal';
import BorrowModal from '../modals/BorrowModal';
import LendModal from '../modals/LendModal';

interface BettingExchangeProps {
  owners: Member[];
}

const BettingExchange: React.FC<BettingExchangeProps> = ({ owners }) => {
  const { currentAuthUser, profile } = useAuth();

  // Generalized Betting Catagories
  const [selectedSpecial, setSelectedSpecial] = useState<any>({ title: "" });


  // All Sports From API
  const [sports, setSports] = useState<Sport[]>([])

  // Place Order Form
  const [betDescription, setBetDescription] = useState('');
  const [betAmount, setBetAmount] = useState('');
  const [odds, setOdds] = useState('');
  const [line, setLine] = useState<Line | null>(null);
  const [event, setEvent] = useState<SportsEventOdds | null>(null);
  const [market, setMarket] = useState<string>('');
  const [orderSide, setOrderSide] = useState<OrderSide>('back');
  const [orderType, setOrderType] = useState<OrderType>('limit');

  // Book?
  const [openBets, setOpenBets] = useState<OpenOrder[]>([]);
  const [liveBets, setLiveBets] = useState<LiveOrder[]>([]);
  const [settledBets, setSettledBets] = useState<SettledOrder[]>([]);

  // Odds Types
  const [recommendedBets, setRecommendedBets] = useState<any[]>([]);
  const [specials, setSpecials] = useState<any[]>([]);
  const [specialBets, setSpecialBets] = useState<any[]>([]);
  const [weddingFutures, setWeddingFutures] = useState<any[]>([]);
  const [highVolumeBets, setHighVolumeBets] = useState<any[]>([]);
  const [outRightBets, setOutRightBets] = useState<any[]>([]);
  const [mlSpreadTots, setMlSpreadTots] = useState<SportsEventOdds[]>([]);

  // Rendered Odds
  const [filteredBets, setFilteredBets] = useState<any[]>([]);
  const [renderedBets, setRenderedBets] = useState<any[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<SportsEventOdds[]>([]);

  // Page State
  const [loading, setLoading] = useState(true);
  const [selectedSport, setSelectedSport] = useState<Sport | null>(null);
  const [selectedCatagory, setSelectedCatagory] = useState<string>("");

  const [selectedBetType, setSelectedBetType] = useState<BetType>('');
  const [openOrder, setOpenOrder] = useState<string | null>(null);

  const handleToggle = (orderId: string) => {
    setOpenOrder(openOrder === orderId ? null : orderId);
  };

  // useEffect(() => {
  //   const getWeddingFutures = async () => {
  //     const weddingFutures = await fetchWeddingFutures();
  //     setWeddingFutures(weddingFutures)
  //   };
  //   getWeddingFutures();
  // }), [selectedPoison === 'WeddingFutures'];

  useEffect(() => {
    setFilteredBets([]);
    setLoading(true);
    const fetchBets = async () => {
      const result1 = await getAllLiveBets();
      if (result1.success) {
        setLiveBets(result1.data);
      }

      const result2 = await getAllOpenBets();
      if (result2.success) {
        setOpenBets(result2.data)
      }

      const result3 = await getSettledBets();
      if (result3.success) {
        setSettledBets(result3.data)
      }
    };

    const fetchAndSetOdds = async () => {
      const sportsResult = await fetchSports();
      if (sportsResult.success) {
        setSports(sportsResult.data)
      }

      if (selectedSport) {
        const response = await fetchOdds(selectedSport.key);
        if (response.success) {
          if (selectedSport.has_outrights) {
            setOutRightBets(response.data)
          }
          else {
            setMlSpreadTots(response.data)
          }
        }
      }

      const specialsQuery = await fetchSpecialsKeyWords();
      if (specialsQuery.success)
        setSpecials(specialsQuery.data)

      // const response = await fetchWeddingFutures();
      // if (response.success)
      //   setWeddingFutures(response.data)

      setLoading(false); // Set loading to false after fetching is complete
    };

    fetchBets();
    fetchAndSetOdds();

    setFilteredBets([])

    if (selectedSport) {
      if (selectedSport?.has_outrights) {
        setSelectedBetType('outRights')
      }
      else {
        setSelectedBetType('mlSpreadTots')
      }
    }

  }, [selectedSport])

  useEffect(() => {
    if (loading) { return; }
    const updateFilteredBets = async () => {
      setFilteredBets(betTypes[selectedBetType]) // TODO: this is wrong
      setFilteredEvents(betTypes[selectedBetType])
      // setMlSpreadTots(betTypes[selectedBetType])
    }
    updateFilteredBets();
  }, [selectedSport, selectedBetType, loading])

  const handleAddToSlip = async () => {
    if (!betDescription || !odds || !betAmount) {
      alert('Please fill in all fields.');
      return;
    }
    const parsedOdds = parseInt(odds, 10);
    const parsedAmount = parseInt(betAmount, 10);

    if (isNaN(parsedOdds) || isNaN(parsedAmount)) {
      alert('Please enter valid numbers for odds and amount.');
      return;
    }

    // const partialBet = {
    //   sport_title: selectedSport?.title,
    //   description: betDescription,
    //   line: line,
    //   market: market,
    //   amount: parsedAmount,
    //   seeker_id: currentAuthUser.uid,
    //   status: 'open',
    // } as PendingOrder;

    // try {
    //   const newBet = await offerBet(partialBet);
    //   setOpenBets([...openBets, newBet]);
    //   setOdds('');
    //   setBetDescription('');
    //   setBetAmount('');
    // } catch (error) {
    //   console.error('Error creating bet:', error);
    //   alert('Failed to create bet. Please try again.');
    // }
  };

  const filterOpenBets = (bet_id: string, market: string, name: string) => {
    // console.log(openBets)
    return openBets.filter((bet) => {
      return bet.event_id === bet_id && bet.market === market && (market !== "outRights" || bet.line.name === name);
    });
  };

  const handlePlaceOrder = async () => {
    if (!betDescription || !odds || !betAmount) {
      alert('Please fill in all fields.');
      return;
    }
    const parsedOdds = parseInt(odds, 10);
    const parsedAmount = parseInt(betAmount, 10);

    if (isNaN(parsedOdds) || isNaN(parsedAmount)) {
      alert('Please enter valid numbers for odds and amount.');
      return;
    }

    if (line === null) {
      alert('Error: Line is Null: Please report this issue and try selecting a different line from the recommended wagers');
      return;
    }

    if (!profile) {
      alert('Ghost Profile Detected');
      return;
    }
    if (profile.wallet - parsedAmount < 0) {
      alert('You are too poor to make this bet.');
      return;
    }

    const partialBet = {
      sport_title: selectedSport?.title ?? 'To Marry First',
      event_id: event ? event.id : 'wedding?',
      description: betDescription,
      market: market,
      line: { ...line, price: parsedOdds },
      amount: parsedAmount,
      seeker_id: currentAuthUser.uid,
      status: 'open',
      orderSide: orderSide,
      orderType: orderType,
    } as PendingOrder;

    try {
      const newBet = await offerBet(partialBet);
      updateUser({ uid: currentAuthUser.uid, wallet: profile.wallet - partialBet.amount })
      profile.wallet -= partialBet.amount;
      setOpenBets([...openBets, newBet]);
      setOdds('');
      setBetDescription('');
      setBetAmount('');
    } catch (error) {
      console.error('Error creating bet:', error);
      alert('Failed to create bet. Please try again.');
    }
  };

  const handleTakeBet = (index: number) => {
    const betToTake = openBets[index];
    if (betToTake.seeker_id === currentAuthUser.uid) {
      return
    }
    takeBet(betToTake.id, currentAuthUser.uid);
    setLiveBets([...liveBets, betToTake]);
    setOpenBets(openBets.filter((_, i) => i !== index));
  };

  type BetType = '' | 'highVolume' | 'specials' | 'outRights' | 'weddingFutures' | 'mlSpreadTots' | 'all'; // 'cut' | 
  type sportsSubSelections = '' | 'highVolume' | 'specials' | 'outRights' | 'weddingFutures' | 'mlSpreadTots' | 'all';
  type subSelections = '' | 'highVolume' | 'specials' | 'outRights' | 'weddingFutures' | 'mlSpreadTots' | 'all';

  const betTypes: { [key in BetType]: any[] } = {
    // cut: cutBets,
    // h2h: h2hBets,
    '': [],
    outRights: outRightBets,
    mlSpreadTots: mlSpreadTots,
    specials: specialBets,
    weddingFutures: weddingFutures,
    all: [...recommendedBets, ...specialBets, ...weddingFutures], // , ...cutBets]
    highVolume: highVolumeBets,
  };

  // const filteredBets = betTypes[selectedBetType] ?? [];
  // console.log(filteredBets)

  const handleBetTypeChange = (event: SelectChangeEvent<string>) => {
    // console.log("BetType: ", event.target.value as BetType)
    setSelectedBetType(event.target.value as BetType);
  };

  const getSpecials = async (special: string, marketType: BetType) => {
    if (!special || !marketType) {
      // console.log("BLEH: ", !special, " ", !marketType)
      return
    }
    const response = await fetchSpecials(special, marketType);
    if (response.success) {
      // console.log(response.data)
      setSpecialBets(response.data)
      setSelectedSport(null)
      // setSelectedBetType(key.market ? key.market : 'mlSpreadTots')
      // setSelectedSpecial(key)
      setFilteredBets(response.data)
    }
    // else
    // console.log("NOPE: ", selectedCatagory, special, marketType)
    // return
  };

  const handleKeywordChange = async (key: any) => {
    // console.log(key)
    setSelectedSpecial(key)

    getSpecials(key.title, selectedBetType);
  };

  const handleMarketChange = async (key: BetType) => {
    // console.log(key)
    setSelectedBetType(key)
    getSpecials(selectedSpecial.title, key);

    // console.log(selectedSpecial.id, key, selectedSpecial)
    // const response = await fetchSpecials(selectedSpecial.title, key);
    // if (response.success) {
    //   console.log(response.data)
    //   setSpecialBets(response.data)
    //   setSelectedSport(null)

    //   // setSelectedPoison(key)
    //   // setSelectedSpecial(key)
    //   setFilteredBets(response.data)
    // }
    // else
    //   console.log("NOPE: ", selectedCatagory, " ", key)
    // return
  };

  const handleSportChange = async (key: any) => {
    // if (key === "weddingFutures") {
    //   setSelectedPoison("weddingFutures")
    //   setSelectedBetType("outRights")
    //   setSelectedSport(null)
    //   setFilteredBets(betTypes['weddingFutures'])
    //   return
    // }
    const sport = sports.find(sport => sport.key === key);
    if (sport) {
      // setSelectedPoison('')
      setSelectedSpecial({ title: '' })
      setSelectedSport(sport);
    }
  };

  const handleCatagoryChange = (key: string) => {
    setSelectedCatagory(key);
    setSelectedSport(null)
  }

  const handleListItemClick = (bet: Line, market: string = '', side: OrderSide = 'back', event: SportsEventOdds | null) => {
    setBetDescription(bet.name);
    setLine(bet);
    setEvent(event);
    setMarket(market);
    // setEvent(event);
    // setBetAmount(bet.amount.toString());
    setOrderSide(side)
    setOdds(bet.price.toString());
  };

  const renderBetButtons = (event: SportsEventOdds, team: string, totalSide: string) => {
    let bookMaker = event.bookmakers.find((bookmaker: any) => bookmaker.key === 'draftkings');
    if (!bookMaker && event.bookmakers.length > 0) {
      bookMaker = event.bookmakers[0]
    }
    // console.log(bookMaker);
    if (!bookMaker) { return null };

    const moneyLine = bookMaker.markets.find(market => market.key === 'h2h');
    const spread = bookMaker.markets.find(market => market.key === 'spreads');
    const total = bookMaker.markets.find(market => market.key === 'totals');

    const getMoneyLine = (market: Market): MoneyLine | undefined => market.outcomes.find(outcome => outcome.name === team) as MoneyLine;
    const getSpread = (market: Market): Spread | undefined => market.outcomes.find(outcome => outcome.name === team) as Spread;
    const getTotal = (market: Market): Total | undefined => market.outcomes.find(outcome => outcome.name === totalSide) as Total;

    return (
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={4} display="flex" justifyContent="center" alignItems="center" p={1}>
          {moneyLine && getMoneyLine(moneyLine) ? (
            <Button variant="outlined" onClick={(clickEvent) => { clickEvent.stopPropagation(); handleListItemClick(getMoneyLine(moneyLine)!, 'h2h', 'back', event) }} sx={{ minWidth: 100, backgroundColor: getMoneyLine(moneyLine)!.price > 0 ? '#104a07' : '#7d1818' }}>
              <Typography noWrap sx={{ minWidth: 100, color: 'white' }}>{getMoneyLine(moneyLine)!.price > 0 ? '+' : ''}{getMoneyLine(moneyLine)!.price}</Typography>
            </Button>
          ) : (
            <Box sx={{ height: 36 }} />
          )}
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="center" alignItems="center" p={1}>
          {spread && getSpread(spread) ? (
            <Button variant="outlined" onClick={(clickEvent) => { clickEvent.stopPropagation(); handleListItemClick(getSpread(spread)!, 'spreads', 'back', event) }} sx={{ minWidth: 100, backgroundColor: getSpread(spread)!.price > 0 ? '#104a07' : '#7d1818' }}>
              <Typography noWrap sx={{ minWidth: 100, color: 'white' }}>{getSpread(spread)!.point}: {getSpread(spread)!.price > 0 ? '+' : ''}{getSpread(spread)!.price}</Typography>
            </Button>
          ) : (
            <Box sx={{ height: 36 }} />
          )}
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="center" alignItems="center" p={1}>
          {total && getTotal(total) ? (
            <Button variant="outlined" onClick={(clickEvent) => { clickEvent.stopPropagation(); handleListItemClick(getTotal(total)!, 'totals', 'back', event) }} sx={{ minWidth: 100, backgroundColor: getTotal(total)!.price > 0 ? '#104a07' : '#7d1818' }}>
              <Typography noWrap sx={{ minWidth: 100, color: 'white' }}>{totalSide[0]}{getTotal(total)!.point}:{getTotal(total)!.price > 0 ? '+' : ''}{getTotal(total)!.price}</Typography>
            </Button>
          ) : (
            <Box sx={{ height: 36 }} />
          )}
        </Grid>
      </Grid>
    );
  };

  const renderOutRights = () => {
    // console.log("FilteredBets: ", filteredBets)
    if (!filteredBets || filteredBets.length === 0 || !filteredBets[0].bookmakers) {
      return null
    }
    let bookMaker = filteredBets[0].bookmakers.find((bookmaker: any) => bookmaker.key === 'draftkings');
    if (!bookMaker && filteredBets[0].bookmakers.length > 0) {
      bookMaker = filteredBets[0].bookmakers[0]
    }

    // filteredBets.map((event: SportsEvent) => {
    // console.log(event)
    return bookMaker.markets[0].outcomes.map((bet: Line, index: number) => (
      <Box key={index}>
        <ListItem key={index}
          onClick={() => handleToggle(bet.name)}//TODO: This needs an id
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'background.default',
            borderRadius: '8px',
            marginBottom: '8px',
            padding: '16px',
            // transition: 'transform 0.2s',
            // '&:hover': {
            //   transform: 'scale(1.02)'
            // },
            display: 'row', border: '1px solid', borderColor: 'divider'
          }}>
          {/* <ListItemText primary={bet.name} secondary={`Odds: ${bet.price > 0 ? "+ " + bet.price : bet.price}`} />
        <Button variant="contained" onClick={() => handleListItemClick(bet)} sx={{ minWidth: 100, m: 1, backgroundColor: '#104a07' }}>
          <Typography noWrap color='white'>Buy: {bet.price > 0 ? '+' : ''}{bet.price}</Typography>
        </Button>
        <Button variant="outlined" onClick={() => handleListItemClick(bet)} sx={{ minWidth: 100, m: 1, backgroundColor: "#7d1818" }}>
          <Typography noWrap color='white' sx={{ minWidth: 100 }}>Sell: {bet.price > 0 ? '+' : ''}{calculateLayOdds(bet.price)}</Typography>
        </Button> */}
          <Box flex='1'>
            <ListItemText primary={bet.name} secondary={selectedSport?.title} />
          </Box>
          <Grid container spacing={2} justifyContent="space-between" flex="1">
            <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
              <Button variant="contained" onClick={(clickEvent) => { handleListItemClick(bet, 'outRights', 'back', filteredBets[0]) }} sx={{ minWidth: 200, backgroundColor: '#521841' }}>
                <Typography sx={{ color: 'white' }} noWrap>See Open Orders</Typography>
              </Button>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
              <Button variant="contained" onClick={(clickEvent) => { clickEvent.stopPropagation(); handleListItemClick(bet, 'outRights', 'back', filteredBets[0]) }} sx={{ minWidth: 200, backgroundColor: '#104a07' }}>
                <Typography sx={{ color: 'white' }} noWrap>Buy: {bet.price > 0 ? '+' : ''}{bet.price}</Typography>
              </Button>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
              <Button variant="contained" onClick={(clickEvent) => { clickEvent.stopPropagation(); handleListItemClick(bet, 'outRights', 'lay', filteredBets[0]) }} sx={{ minWidth: 200, borderWidth: 2, backgroundColor: '#7d1818' }}>
                <Typography sx={{ color: 'white' }} noWrap>Sell {calculateLayOdds(bet.price) > 0 ? '+' : ''}{calculateLayOdds(bet.price)}</Typography>
              </Button>
            </Grid>
          </Grid>
        </ListItem>
        <Collapse in={openOrder === bet.name}>
          <Box sx={{ pl: 2, pb: 3 }}>
            <BidAskChart openBets={filterOpenBets(filteredBets[0].id, 'outRights', bet.name)} />
          </Box>
        </Collapse>
      </Box>

    ))
    // });
  };

  const renderMLSpreadTot = () => {
    // console.log("FilteredBets: ", filteredBets)
    if (!filteredBets) {
      return null
    }
    return filteredBets.map((event: SportsEventOdds, index: number) => {
      return (
        <Box key={index}>
          <ListItem key={event.id}
            onClick={() => handleToggle(event.id)}
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'background.default',
              borderRadius: '8px',
              marginBottom: '8px',
              padding: '16px',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.02)'
              },
              border: '1px solid', borderColor: 'divider'
            }}>
            <Box flex="1">
              <Typography variant="h6">{event.home_team}</Typography>
              <Typography variant="h6">{event.away_team}</Typography>
            </Box>
            <Box flex='1' display={{ xs: 'none', md: 'block' }}>
              <Typography variant="body1">{event.description}</Typography>
            </Box>
            <Box flex="1" display="flex" flexDirection="column" alignItems="center">
              {renderBetButtons(event, event.home_team, "Over")}
              {renderBetButtons(event, event.away_team, "Under")}
            </Box>
          </ListItem>
          <Collapse in={openOrder === event.id}>
            <Box sx={{ pl: 2, pb: 3, display: 'flex', flexDirection: 'row', gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                <BidAskChart openBets={filterOpenBets(event.id, 'h2h', 'idk')} />
              </Box>
              <Box sx={{ flex: 1 }}>
                <BidAskChart openBets={filterOpenBets(event.id, 'spreads', 'idk')} />
              </Box>
              <Box sx={{ flex: 1 }}>
                <BidAskChart openBets={filterOpenBets(event.id, 'totals', 'idk')} />
              </Box>
            </Box>
          </Collapse>
        </Box>
      );
    });
  };

  const renderWeddingFutures = () => {
    return (
      <List sx={{ maxHeight: 5000, overflowY: 'auto', scrollbarWidth: 'none' }}>
        <Box sx={
          { display: 'grid', gridTemplateColumns: '1fr', gap: 2 }}>
          {filteredBets && filteredBets.map((bet: Line, index: number) => (
            <Box key={index}>
              <ListItem key={index}
                onClick={() => handleToggle(bet.name)} //TODO: This needs an id
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: 'background.default',
                  borderRadius: '8px',
                  // transition: 'transform 0.2s',
                  // '&:hover': {
                  //   transform: 'scale(1.01)'
                  // },
                  border: '1px solid',
                  borderColor: 'divider'
                }}>
                <Box flex='1'>
                  <ListItemText primary={bet.name} secondary={'To Marry First'} />
                </Box>
                <Grid container spacing={2} justifyContent="space-between" flex="1">
                  <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                    <Button variant="contained" onClick={(clickEvent) => { handleListItemClick(bet, 'outRights', 'back', filteredBets[0]) }} sx={{ minWidth: 200, backgroundColor: '#521841' }}>
                      <Typography sx={{ color: 'white' }} noWrap>See Open Orders</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                    <Button variant="contained" onClick={(event) => { event.stopPropagation(); handleListItemClick(bet, 'outRights', 'back', null); }} sx={{ minWidth: 200, backgroundColor: '#104a07' }}>
                      <Typography sx={{ color: 'white' }} noWrap>Buy: {bet.price > 0 ? '+' : ''}{bet.price}</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                    <Button variant="contained" onClick={(event) => { event.stopPropagation(); handleListItemClick(bet, 'outRights', 'lay', null); }} sx={{ minWidth: 200, borderWidth: 2, backgroundColor: "#7d1818" }}>
                      <Typography sx={{ color: 'white' }} noWrap>Sell {calculateLayOdds(bet.price) > 0 ? '+' : ''}{calculateLayOdds(bet.price)}</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
              <Collapse in={openOrder === bet.name}>
                <Box sx={{ flex: '1', p: 2 }}>
                  <BidAskChart openBets={filterOpenBets("wedding?", 'outRights', bet.name)} />
                </Box>
              </Collapse>
            </Box>
          ))}
        </Box>
      </List>);
  };

  const renderBets = () => {
    if (loading) { return <></> }

    if (selectedSpecial.id === "weddingFutures") {
      return renderWeddingFutures();
    }

    switch (selectedBetType) {
      case 'outRights':
        return renderOutRights();
      case 'mlSpreadTots':
        return renderMLSpreadTot();
      default:
        // console.log("WRONG: ", selectedBetType)
        break;
    }
  };

  const renderHeader = () => {
    return (
      <>
        {
          selectedBetType === "outRights" ? (
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'background.default',
              borderRadius: '8px',
              padding: '16px',
              border: '1px solid',
              borderColor: 'divider'
            }}>
              <Box flex='1'>
                <Typography variant="h6">Wager</Typography>
              </Box>
              <Grid container spacing={2} justifyContent="space-between" flex="1">
                <Grid item xs={6} display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="h6" noWrap>Buy</Typography>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="h6" noWrap sx={{ minWidth: 100 }}>Sell</Typography>
                </Grid>
              </Grid>
            </Box >
          ) : (
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'background.default',
              borderRadius: '8px',
              padding: '16px',
              border: '1px solid',
              borderColor: 'divider'
            }}>
              <Box flex='1'>
                <Typography>Participants</Typography>
              </Box>
              <Box flex='1' display={{ xs: 'none', md: 'block' }}>
                <Typography variant="body1">Details</Typography>
              </Box>
              <Grid container spacing={2} justifyContent="space-between" flex="1">
                <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                  <Typography noWrap sx={{ minWidth: 100 }}>Money Line</Typography>
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                  <Typography>Spread</Typography>
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                  <Typography>Total</Typography>
                </Grid>
              </Grid>
            </Box >
          )
        }
      </>
    )
  };

  const renderEventHeader = () => {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'background.default',
        borderRadius: '8px',
        padding: '16px',
        border: '1px solid',
        borderColor: 'divider'
      }}>
        <Grid container spacing={2} justifyContent="space-between" flex="1">
          <Grid item xs={3} display="flex">
            <Typography variant="h6">Teams</Typography>
          </Grid>
          <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h6" noWrap>Info</Typography>
          </Grid>
          <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h6" noWrap>Location</Typography>
          </Grid>
          <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h6" noWrap sx={{ minWidth: 100 }}>Commences</Typography>
          </Grid>
        </Grid>
      </Box >
    );
  };

  const renderEvents = () => {
    // console.log("FilteredBets: ", filteredBets)
    if (!filteredBets || filteredBets.length === 0) {
      return null
    }

    // filteredBets.map((event: SportsEvent) => {
    console.log(filteredBets)
    return filteredBets.map((event: SportsEvent, index: number) => (
      <Box key={index}>
        <ListItem key={index}
          onClick={() => { }}//TODO: This needs an id
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'background.default',
            borderRadius: '8px',
            marginBottom: '8px',
            padding: '16px',
            // transition: 'transform 0.2s',
            // '&:hover': {
            //   transform: 'scale(1.02)'
            // },
            display: 'row', border: '1px solid', borderColor: 'divider'
          }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              {/* <ListItemText primary={event.sport_title} secondary={event.sport_key} /> */}
              <Typography variant="body2">{event.home_team}</Typography>
              <Typography variant="body2">{event.away_team}</Typography>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
              <Typography noWrap>{event.description}</Typography>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
              <Typography noWrap>{event.location}</Typography>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body1">{new Date(event.commence_time).toLocaleString()}</Typography>
            </Grid>
          </Grid>
        </ListItem>
        {/* <Collapse in={openOrder === bet.name}>
          <Box sx={{ pl: 2, pb: 3 }}>
            <BidAskChart openBets={filterOpenBets(filteredBets[0].id, 'outRights', bet.name)} />
          </Box>
        </Collapse> */}
      </Box>

    ))
    // });
  };

  const renderEventModule = () => {
    // console.log("FilteredBets: ", filteredBets)
    return (
      <>
        {renderEventHeader()}
        <List sx={{ maxHeight: 600, overflowY: 'auto', scrollbarWidth: 'none' }}>
          {renderEvents()}
        </List>
      </>
    );
  };

  const renderWagers = () => {
    // console.log("FilteredBets: ", filteredBets)
    return (
      <>
        {renderHeader()}
        <List sx={{ maxHeight: 600, overflowY: 'auto', scrollbarWidth: 'none' }}>
          {renderBets()}
        </List>
      </>
    );
  };

  const renderBetTypes = () => {
    return [
      <MenuItem key="boys" value="The Boys">The Boys</MenuItem>,
      <MenuItem key="sports" value="Pro Sports">Pro Sports</MenuItem>,
      <MenuItem key="misc" value="Miscellaneous">Miscellaneous</MenuItem>,
    ];
    // if (selectedPoison === "weddingFutures") {
    //   return [<MenuItem key="outRights" value="outRights">OutRight</MenuItem>];
    // }

    // if (!selectedSport) { return [] };

    // if (filteredBets) {
    //   // console.log("FilteredBets: ", filteredBets)
    // }

    // if (selectedSport.has_outrights) {
    //   return [<MenuItem key="outRights" value="outRights">OutRight</MenuItem>];
    // } else {
    //   return [
    //     <MenuItem key="mlSpreadTots" value="mlSpreadTots">ML/Spread/Totals</MenuItem>,
    //     <MenuItem key="highVolume" value="highVolume">High Volume</MenuItem>,
    //     <MenuItem key="specials" value="specials">Specials</MenuItem>,
    //   ];
    // }
  };

  return (
    // <Container maxWidth="lg" sx={{ mt: 2 }}>
    <Grid container spacing={2} sx={{ height: '100%', width: '100%' }}>
      <Grid item xs={12}>
        <Box sx={{ backgroundColor: "background.default", borderRadius: 2, padding: 5 }}>
          <Typography variant="h5" align="center" gutterBottom>Sports Betting Exchange</Typography>
          <List>
            <Paper elevation={1} sx={{ mt: 2, p: 1, backgroundColor: 'background.default' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, textAlign: 'center', backgroundColor: 'background.default', m: 2, borderRadius: 10, p: 5 }}>
                  <Typography variant='h5'>Hello {profile?.nickName}</Typography>
                  {/* {/* </Box> */}
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, textAlign: 'center' }}>
                    <Typography variant={'h6'} sx={{ p: 1 }}>Balance: </Typography>
                    <Typography variant={'h6'} color={'green'}>
                      {profile ? profile.wallet.toFixed(2) : ''} $
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', m: 2, flex: 1, textAlign: 'center' }}>
                  <BookBankModal />
                  <BorrowModal />
                  <LendModal />
                </Box>
                {/* </Grid>
                </Grid> */}
              </Box>
            </Paper>
          </List>
        </Box>
      </Grid>

      <Grid item xs={6} md={0}>
        <Box sx={{ backgroundColor: "background.default", borderRadius: 2, padding: 3 }}>
          <Typography pb={1} variant="h5" gutterBottom align="center">Choose a League</Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Pick a League</InputLabel>
            <Select
              value={selectedCatagory}
              onChange={e => handleCatagoryChange(e.target.value)}
              label="Pick a Catagory"
            // value={true}
            >
              {renderBetTypes()}
              {/* <MenuItem key="weddingFutures" value="weddingFutures">Wedding Futures</MenuItem>
              {sports.map((sport: Sport, index: number) => {
                return (
                  <MenuItem key={sport.key} value={sport.key}>{sport.title}: {sport.description}</MenuItem>
                )
              })} */}
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={6} md={0}>
        <Box sx={{ backgroundColor: "background.default", borderRadius: 2, padding: 3 }}>
          {selectedCatagory === "Pro Sports" && (
            <Typography pb={1} variant="h5" gutterBottom align="center">Sport</Typography>)}
          {selectedCatagory === "The Boys" && (
            <Typography pb={1} variant="h5" gutterBottom align="center">Choose a Topic and Market</Typography>)}
          <FormControl fullWidth sx={{ mb: 2 }}>
            {selectedCatagory === "Pro Sports" && (
              <>
                <InputLabel>Select Sport</InputLabel>
                <Select
                  value={selectedSport ? selectedSport.key : ""}
                  onChange={e => handleSportChange(e.target.value)}
                  label="Select Sport"
                // value={true}
                >
                  {/* <MenuItem key="weddingFutures" value="weddingFutures">Wedding Futures</MenuItem> */}
                  {sports.map((sport: Sport, index: number) => {
                    return (
                      <MenuItem key={sport.key} value={sport.key}>{sport.title}: {sport.description}</MenuItem>
                    )
                  })}
                </Select>
              </>
            )}
            {selectedCatagory === "The Boys" && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <FormControl fullWidth sx={{ mb: 0 }}>
                    <InputLabel>Select Topic</InputLabel>
                    <Select
                      value={selectedSpecial}
                      onChange={e => handleKeywordChange(e.target.value)}
                      label="Select Catagory"
                      fullWidth
                    // value={true}
                    >
                      {/* <MenuItem key="weddingFutures" value="weddingFutures">Wedding Futures</MenuItem> */}
                      {specials.map((special: any, index: number) => {
                        return (
                          <MenuItem key={index} value={special}>{special.title}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 0 }}>
                    <InputLabel>Select Market</InputLabel>
                    <Select
                      value={selectedBetType}
                      onChange={e => handleMarketChange(e.target.value as BetType)}
                      label="Select Market"
                      fullWidth
                    // value={true}
                    >
                      <MenuItem value={'outRights'}>Out Right Winners (More Than 2 Parties Competing)</MenuItem>
                      <MenuItem value={'mlSpreadTots'}>Money Lines, Spreads, and Totals</MenuItem>
                    </Select>
                  </FormControl>
                  <SubmitLinesModal />
                </Box>
              </>
            )}
          </FormControl>
        </Box>
      </Grid>

      {(selectedSport || selectedSpecial) &&
        <Grid item xs={12} md={0}>
          <Box sx={{ backgroundColor: "background.default", borderRadius: 2, padding: 3 }}>
            <Typography sx={{ p: 1 }} variant="h5" gutterBottom align="center">Events</Typography>
            {renderEventModule()}
          </Box>
        </Grid>
      }

      {(selectedSport || selectedSpecial) &&
        <Grid item xs={12} md={0}>
          <Box sx={{ backgroundColor: "background.default", borderRadius: 2, padding: 3 }}>
            <Typography sx={{ p: 1 }} variant="h5" gutterBottom align="center">Wagers</Typography>
            {/* <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Bet Type</InputLabel>
              <Select
                value={selectedBetType}
                onChange={handleBetTypeChange}
                label="Bet Type"
              >
                {renderBetTypes()}
              </Select>
            </FormControl> */}
            {renderWagers()}
          </Box>
        </Grid>
      }

      <Grid item xs={6} md={4}>
        <Box sx={{ backgroundColor: "background.default", borderRadius: 2, padding: 3 }}>

          <Typography variant="h5" gutterBottom align="center">Customize Order</Typography>

          <Grid container spacing={0} sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={6}>
              <Button
                variant={orderType === 'market' ? 'contained' : 'outlined'}
                color="primary"
                size={'large'}
                onClick={() => setOrderType('market')}
                sx={{ minWidth: 100 }}
                fullWidth
              >
                Market
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                size={'large'}
                variant={orderType === 'limit' ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => setOrderType('limit')}
                sx={{ minWidth: 100 }}
                fullWidth
              >
                Limit
              </Button>
            </Grid>
          </Grid>
          {orderType === 'market' &&
            <Typography
              sx={{ mb: 2, flex: 1, alignItems: 'center' }}
            >
              I HIGHLY recommend you set a limit
            </Typography>
          }
          <TextField
            fullWidth
            label="Description"
            value={betDescription}
            onChange={(e) => setBetDescription(e.target.value)}
            InputProps={{ readOnly: true }}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Amount"
            value={betAmount}
            onChange={(e) => setBetAmount(e.target.value)}
            sx={{ mb: 2 }}
          />
          {orderType === 'limit' &&
            <TextField
              fullWidth
              label="Odds"
              value={odds}
              onChange={(e) => setOdds(e.target.value)}
              sx={{ mb: 2 }}
            />
          }
          <Grid container spacing={0} sx={{ mb: 3 }}>
            <Grid item xs={6}>
              <Button
                variant={orderSide === 'back' ? 'contained' : 'outlined'}
                color="success"
                size={'large'}
                onClick={() => setOrderSide('back')}
                sx={{ minWidth: 100, backgroundColor: orderSide === 'back' ? '#104a07' : 'background.default' }}
                fullWidth
              >
                <Typography color={'white'}>Buy</Typography>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                size={'large'}
                variant={orderSide === 'lay' ? 'contained' : 'outlined'}
                // color="error"
                onClick={() => setOrderSide('lay')}
                sx={{ minWidth: 100, backgroundColor: orderSide === 'lay' ? '#7d1818' : 'background.default' }}
                fullWidth
              >
                <Typography color={'white'}>Sell</Typography>
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ display: 'row', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Button fullWidth variant="contained" color="secondary" size={'large'} sx={{ mb: 2, backgroundColor: '#521841' }} onClick={handlePlaceOrder}>
              <Typography noWrap sx={{ minWidth: 100, flex: 1, color: 'white' }}>Place order</Typography>
            </Button>
            {/* <Button fullWidth variant="contained" color="info" size={'large'} sx={{ mb: 2, backgroundColor: '#154647' }} onClick={handleAddToSlip}>
                <Typography noWrap sx={{ minWidth: 100, flex: 1, color: 'white' }}>Add to Bet Slip</Typography>
              </Button> */}
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={8}>
        <Box sx={{ backgroundColor: "background.default", borderRadius: 2, padding: 3 }}>
          <Typography variant="h5" gutterBottom align="center">Open Orders</Typography>
          <List sx={{ maxHeight: 400, overflowY: 'auto', scrollbarWidth: 'none' }}>
            {openBets.map((bet: OpenOrder, index) => (
              <ListItem key={index} sx={{
                display: 'row', justifyContent: 'space-between', alignItems: 'space-between', marginBottom: 1, padding: 2, border: '1px solid', borderColor: 'divider', borderRadius: 2,
                // transition: 'transform 0.2s',
                // '&:hover': {
                //   transform: 'scale(1.02)'
                // },
              }}>
                {/* <ListItemText primary={bet.description + " " + bet.sport_title} secondary={market2Text(bet.market)} sx={{ flex: 8 }} /> */}
                <ListItemText primary={getPrimaryText(bet)} secondary={getSecondaryText(bet)} sx={{ flex: 2 }} />
                <Typography noWrap sx={{ minWidth: 150, flex: 1 }}>
                  {/* {bet.amount} $ @ {calculateLayOdds(bet.line.price) > 0 ? '+' : ''}{calculateLayOdds(bet.line.price)} */}
                  {bet.amount}$ @ {bet.line.price > 0 ? '+' : ''}{bet.line.price}
                </Typography>
                {bet.seeker_id !== currentAuthUser.uid ? (
                  <Button variant="contained" color="secondary" onClick={() => handleTakeBet(index)} sx={{ minWidth: 0, flex: 1, backgroundColor: () => (bet.orderSide === "lay") ? '#104a07' : '#7d1818' }}>
                    <Typography noWrap sx={{ minWidth: 0, flex: 1, color: 'white' }}>
                      {bet.orderSide === 'lay' ? 'Buy' : 'Sell'} for {(Math.ceil(calculateOpenContractPrice(bet) * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}$
                    </Typography>
                  </Button>
                ) : (
                  <Button variant="outlined" color="secondary" sx={{ minWidth: 0, opacity: .3, flex: 1, backgroundColor: () => (bet.orderSide === "lay") ? '#104a07' : "#7d1818" }}>
                    <Typography noWrap sx={{ minWidth: 0, flex: 1, color: 'white' }}>{bet.orderSide === 'lay' ? 'Buy' : 'Sell'}</Typography>
                  </Button>)
                }
                {/* <Button variant="contained" color="secondary" onClick={() => handleTakeBet(index)} sx={{ minWidth: 0, flex: 1, backgroundColor: () => (bet.orderSide === "lay") ? '#104a07' : "#7d1818" }}>
                    <Typography noWrap sx={{ minWidth: 0, flex: 1, color: 'white' }}>{bet.orderSide === 'lay' ? 'Buy' : 'Sell'} Partial</Typography>
                  </Button> */}
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ backgroundColor: "background.default", borderRadius: 2, padding: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, textAlign: 'center' }}>
              <ListItemText primary={"Buyer / Backer"} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 2, textAlign: 'center' }}>
              <Typography variant="h5" align="center" gutterBottom>Live Contracts</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, textAlign: 'center' }}>
              <ListItemText primary={'Seller / Layer'} />
            </Box>
          </Box>

          <List>
            {liveBets.map((bet, index) => (
              <Box sx={{ m: 1, p: 3, backgroundColor: 'background.default', borderRadius: 2, borderWidth: 2, border: '1px solid', borderColor: 'divider' }} key={index} borderColor={'divider'}>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, textAlign: 'center' }}>
                    <ListItemText primary={owners.find(owner => owner.id === bet.seeker_id)?.nickName ?? 'Unknown'} />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 2, textAlign: 'center' }}>
                    <ListItemText primary={getPrimaryText(bet)} secondary={`${bet.amount}$ @ ${bet.line.price > 0 ? '+' : ''}${bet.line.price}`} />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, textAlign: 'center' }}>
                    <ListItemText primary={owners.find(owner => owner.id === bet.taker_id)?.nickName ?? 'DEAD'} />
                  </Box>
                </Box>
              </Box>
            ))}
          </List>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ backgroundColor: "background.default", borderRadius: 2, padding: 3 }}>
          <Typography variant="h5" align="center" gutterBottom>Settled Contracts</Typography>
          <List>
            {settledBets.map((bet, index) => (
              <Paper elevation={1} sx={{ mb: 1, p: 1, backgroundColor: 'background.default' }} key={index}>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, textAlign: 'center' }}>
                    <ListItemText primary={owners.find(owner => owner.id === bet.seeker_id)?.nickName ?? 'Unknown'} />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 2, textAlign: 'center' }}>
                    <ListItemText primary={bet.description} secondary={`${bet.amount} $`} />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, textAlign: 'center' }}>
                    <ListItemText primary={owners.find(owner => owner.id === bet.taker_id)?.nickName ?? 'DEAD'} />
                  </Box>
                </Box>
              </Paper>
            ))}
          </List>
        </Box>
      </Grid>
    </Grid >
    // </Container >
  );
};

export default BettingExchange;
