// src/components/AdminDashboard.tsx
import React, { useEffect, useState } from 'react';
import makeAdmin from '../makeAdmin';
import { TextField, Button, Container, Typography, Box, Alert, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ScrapeButton from '../components/ScrapeButton';
import { useNavigate } from 'react-router-dom';
import { isValidEmail } from '../Util';
import GetSportsButton from '../components/casino/GetSportsButton';
import GetOddsButton from '../components/getOddsButton';

const AdminDashboard: React.FC = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');

  // TODO: Make a general League Config Context?
  const [sortingMethod, setSortingMethod] = useState<string>('rank');

  const handleMakeAdmin = async () => {

    if (!isValidEmail(email)) {
      setError('Invalid Email');
      return
    }

    try {
      const resultMessage = await makeAdmin(email);
      setMessage(resultMessage);
      setError('');
    } catch (error: any) {
      setMessage('');
      setError(error.message);
    }
  };

  const backToDash = () => {
    navigate('/dashboard')
  }

  return (
    <Container component="main" maxWidth="lg" sx={{ mt: 0, justifyContent: 'flex-end' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '60px' }}>
        <Button
          type="button"
          variant="outlined"
          onClick={backToDash}
        >
          Back To DashBoard
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'background.paper',
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          mt: 3,
          '& > *': {
            marginBottom: 2, // Apply margin bottom to all direct children
            // width: '100%',  // Ensure all children take full width
          }
        }}
      >
        <Typography component="h1" variant="h5" gutterBottom>
          Admin Dashboard
        </Typography>
        <Box sx={{ justifyContent: 'center', alignItems: 'center', mb: 3 }}>
          <ScrapeButton />
          <GetSportsButton />
          <GetOddsButton />
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 3 }}>
            <TextField
              variant="filled"
              margin="normal"
              required
              // fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mr: 4 }}
            />
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleMakeAdmin}
              sx={{ mt: 0 }}
            >
              Make Admin
            </Button>
          </Box>
          {message && <Alert sx={{ mb: 2 }} severity="success">{message}</Alert>}
          {error && <Alert sx={{ mb: 2 }} severity="error">{error}</Alert>}
          <FormControl fullWidth variant="outlined" sx={{ mt: 10 }}>
            <InputLabel id="sorting-method-label">Sort By</InputLabel>
            <Select
              labelId="sorting-method-label"
              value={sortingMethod}
              onChange={(e) => setSortingMethod(e.target.value as string)}
              label="Sort By"
            >
              <MenuItem value="rank">Rank</MenuItem>
              <MenuItem value="odds">Odds</MenuItem>
            </Select>
          </FormControl>
          <Box>
            <br/><br/><br/>
            To Do: <br/><br/>
            Add Bets to Firebase<br/>
            Fix Fellas page<br/>
            Run Scraper on repeat tomorrow<br/>
            Find Sports Betting data api
            Really need a nickname on login<br/>
            Holes To Play per team<br/>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default AdminDashboard;