// src/components/PayPalButton.tsx
import React, { useEffect } from 'react';
import { PayPalButtons, PayPalScriptProvider, ReactPayPalScriptOptions, PayPalButtonsComponentProps } from '@paypal/react-paypal-js';
import { ReactDOM } from 'react';
import { Box } from '@mui/material';

declare const paypal: any;

interface PayPalllButtonProps {
  handlePayPalSuccess: ()=>Promise<void>
}



export const PayPalButton: React.FC<PayPalllButtonProps> = ({handlePayPalSuccess}) => {

  // const initialOptions: ReactPayPalScriptOptions = {
  //   clientId: "AUYC5AZSvhyRM9l2sK54pySaN7mCU9xaLGnLlKws8-SPMuq1DVrBMIv_GtVap6mOqmUVZatlODrsGfli",
  // };
  const initialOptions: ReactPayPalScriptOptions = {
    'clientId': "AUYC5AZSvhyRM9l2sK54pySaN7mCU9xaLGnLlKws8-SPMuq1DVrBMIv_GtVap6mOqmUVZatlODrsGfli",
    'currency': 'USD',
    'components': 'buttons,funding-eligibility',
    'enable-funding': 'venmo'
  };

  const styles: PayPalButtonsComponentProps["style"] = {
    shape: "rect",
    layout: "horizontal",
  };

  const createOrder = async (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: '10.00', // Set the amount here
        },
      }],
    });
  };

  const onApprove = async (data: any, actions: any) => {
    const details = await actions.order.capture();
    // console.log('Order details:', details);
    alert('Transaction completed by ' + details.payer.name.given_name);
    handlePayPalSuccess();
  };

  const onError = (err: any) => {
    console.error('Error:', err);
    alert('An error occurred during the transaction.');
  };


  // Loop over each funding source / payment method

  // const getEligibleButtons = (paypal: any) => {
  //   paypal.getFundingSources().forEach(function (fundingSource: string) {
  //     // Initialize the buttons
  //     var button = paypal.Buttons({
  //       fundingSource: fundingSource
  //     });
  //     // Check if the button is eligible
  //     if (button.isEligible()) {
  //       // Render the standalone button for that funding source
  //       button.render('#paypal-button-container');
  //     }
  //   })
  // };
  useEffect(() => {
    // Ensure the script is loaded
    const script = document.createElement('script');
    script.src = "https://www.paypal.com/sdk/js?client-id=AUYC5AZSvhyRM9l2sK54pySaN7mCU9xaLGnLlKws8-SPMuq1DVrBMIv_GtVap6mOqmUVZatlODrsGfli&buyer-country=US&currency=USD&components=buttons,funding-eligibility,marks&enable-funding=venmo";
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      if (typeof paypal !== 'undefined') {
        paypal.getFundingSources().forEach((fundingSource: string) => {
          if (paypal.Buttons) {
            const button = paypal.Buttons({
              fundingSource: fundingSource
            });
            if (button.isEligible()) {
              button.render('#paypal-button-container');
            }
          }
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <PayPalScriptProvider options={initialOptions}>
      {/* <PayPalButtons
        style={styles}
        createOrder={createOrder}
        onApprove={onApprove}
        onError={onError}
        fundingSource={window.innerWidth <= 768 ? 'venmo' : 'paypal'}
      /> */}
      <div id="paypal-button-container"></div>
      <Box></Box>
    </PayPalScriptProvider>
  );
}

//   return (
//     <>
//       <script src="https://www.paypal.com/sdk/js?client-id=AUYC5AZSvhyRM9l2sK54pySaN7mCU9xaLGnLlKws8-SPMuq1DVrBMIv_GtVap6mOqmUVZatlODrsGfli&buyer-country=US&currency=USD&components=buttons,funding-eligibility,marks&enable-funding=venmo"></script>
//       <PayPalScriptProvider options={initialOptions}>
//         <PayPalButtons
//           style={styles}
//           createOrder={createOrder}
//           onApprove={onApprove}
//           onError={onError}
//           fundingSource={window.innerWidth <= 768 ? paypal.FUNDING.VENMO : paypal.FUNDING.PAYPAL}
//         />
//         <div id="paypal-button-container"></div>
//       </PayPalScriptProvider>
//       {getEligibleButtons(paypal)}
//     </>
//   );
// }

// return (
//   <PayPalScriptProvider options={{ "clientId": "AQmYzSdOXINOipVIwY4DyDEbLEsCoiVAPlO7yYznR84Qos7cq4KUY8vD093zY1Tvpqb0QdnT7d06DOhs" }}>
//     <PayPalButtons
//       fundingSource="venmo"
//       style={styles}
//       createOrder={(data, actions) => {
//         return actions.order.create({
//           purchase_units: [{
//             amount: {
//               currency_code: 'USC',
//               value: '1.00' // Set the amount here
//             }
//           }],
//           intent: 'CAPTURE'
//         });
//       }}
//       onApprove={(data, actions) => {
//         return actions?.order?.capture().then(details => {
//           alert('Transaction completed by ' + (details?.payer?.name?.given_name ?? 'Guest'));
//         }) ?? Promise.reject(new Error("Failed to capture order"));
//       }}
//       onError={() => {
//         console.log("ERROR BRUH")
//       }}
//     />
//   </PayPalScriptProvider>
// );
// };

export default PayPalButton;
